<template>
    <form class="form-custom">
        <table class="uk-table uk-table-middle uk-table-divider uk-table-justify uk-table-hover uk-table-responsive">
            <thead>
            <tr>
                <th>{{ translations.name }}</th>
                <th>{{ translations.shortcode }}</th>
                <th>{{ translations.franco_amount }}</th>
                <th>{{ translations.shipping_cost }}</th>
                <th>{{ translations.dropshipping_franco_amount }}</th>
                <th>{{ translations.dropshipping_shipping_cost }}</th>
                <!--<th>{{ translations.active }}</th>-->
            </tr>
            </thead>
            <transition-group name="list" tag="tbody">
                <tr class="list-item" v-for="(country, key) in active" :key="country.id">
                    <td>{{ country.name }}</td>
                    <td>{{ country.shortcode }}</td>
                    <td>
                        <input type="number" class="uk-input" step="0.01" :value="format(country.franco_amount)" @change="event => changeNumberHandler(event.currentTarget.value, country.id, 'franco_amount')">
                        <span v-if="errors[country.id] && errors[country.id]['franco_amount']" class="uk-text-danger">{{ errors[key]['franco_amount'][0] }}</span>
                    </td>
                    <td>
                        <input type="number" class="uk-input" step="0.01" :value="format(country.shipping_cost)" @change="event => changeNumberHandler(event.currentTarget.value, country.id, 'shipping_cost')">
                        <span v-if="errors[country.id] && errors[country.id].shipping_cost" class="uk-text-danger">{{ errors[key].shipping_cost[0] }}</span>
                    </td>
                    <td>
                        <input type="number" class="uk-input" step="0.01" :value="format(country.dropshipping_franco_amount)" @change="event => changeNumberHandler(event.currentTarget.value, country.id, 'dropshipping_franco_amount')">
                        <span v-if="errors[country.id] && errors[country.id].dropshipping_franco_amount" class="uk-text-danger">{{ errors[key].dropshipping_franco_amount[0] }}</span>
                    </td>
                    <td>
                        <input type="number" class="uk-input" step="0.01" :value="format(country.dropshipping_shipping_cost)" @change="event => changeNumberHandler(event.currentTarget.value, country.id, 'dropshipping_shipping_cost')">
                        <span v-if="errors[country.id] && errors[country.id].dropshipping_shipping_cost" class="uk-text-danger">{{ errors[key].dropshipping_shipping_cost[0] }}</span>
                    </td>
                    <!--<td>-->
                        <!--<toggle-input :value="country.active" v-on:input="updateActive(country.id)"></toggle-input>-->
                    <!--</td>-->
                </tr>
                <tr class="list-item" v-for="(country, key) in inactive" :key="country.id">
                    <td>{{ country.name }}</td>
                    <td>{{ country.shortcode }}</td>
                    <td>
                        <input type="number" class="uk-input" step="0.01" :value="format(country.franco_amount)" @change="event => changeNumberHandler(event.currentTarget.value, country.id, 'franco_amount')">
                        <span v-if="errors[country.id] && errors[country.id]['franco_amount']" class="uk-text-danger">{{ errors[key]['franco_amount'][0] }}</span>
                    </td>
                    <td>
                        <input type="number" class="uk-input" step="0.01" :value="format(country.shipping_cost)" @change="event => changeNumberHandler(event.currentTarget.value, country.id, 'shipping_cost')">
                        <span v-if="errors[country.id] && errors[country.id].shipping_cost" class="uk-text-danger">{{ errors[key].shipping_cost[0] }}</span>
                    </td>
                    <td>
                        <input type="number" class="uk-input" step="0.01" :value="format(country.dropshipping_franco_amount)" @change="event => changeNumberHandler(event.currentTarget.value, country.id, 'dropshipping_franco_amount')">
                        <span v-if="errors[country.id] && errors[country.id].dropshipping_franco_amount" class="uk-text-danger">{{ errors[key].dropshipping_franco_amount[0] }}</span>
                    </td>
                    <td>
                        <input type="number" class="uk-input" step="0.01" :value="format(country.dropshipping_shipping_cost)" @change="event => changeNumberHandler(event.currentTarget.value, country.id, 'dropshipping_shipping_cost')">
                        <span v-if="errors[country.id] && errors[country.id].dropshipping_shipping_cost" class="uk-text-danger">{{ errors[key].dropshipping_shipping_cost[0] }}</span>
                    </td>
                    <!--<td>-->
                        <!--<toggle-input :value="country.active" v-on:input="updateActive(country.id)"></toggle-input>-->
                    <!--</td>-->
                </tr>
            </transition-group>
        </table>
    </form>
</template>

<script>
    import ToggleInput from "./ToggleInput";
    import { EventBus } from '../EventBus';

    export default {
        name: "CountriesComponent",
        components: {ToggleInput},
        data() {
            return {
                countries: this.initCountries,
                errors: {}
            }
        },
        computed: {
            active() {
                const filtered = _.filter(this.countries, country => country.active == true );
                return _.sortBy(filtered, 'shortcode');
            },
            inactive() {
                const filtered = _.filter(this.countries, country => country.active == false );
                return _.sortBy(filtered, 'shortcode');
            }
        },
        methods: {
            format(number) {
                return number / 100;
            },
            changeNumberHandler(value, id, property){
                const key = _.findKey(this.countries, (item) => (item.id == id));

                this.countries[key][property] = Math.round(value * 100);
                this.update(id);
            },
            updateActive(id) {
                const key = _.findKey(this.countries, (item) => (item.id == id));
                this.countries[key].active = !this.countries[key].active;
                this.update(id);
            },
            update(id) {
                const key = _.findKey(this.countries, (item) => (item.id == id));

                const elem = {
                    _method: 'PUT',
                    franco_amount: this.countries[key]['franco_amount'],
                    shipping_cost: this.countries[key]['shipping_cost'],
                    dropshipping_franco_amount: this.countries[key]['dropshipping_franco_amount'],
                    dropshipping_shipping_cost: this.countries[key]['dropshipping_shipping_cost'],
                    active: this.countries[key]['active'],
                };
                const route = this.routes.update.replace('|id|', id);
                window.axios.post(route, elem)
                    .catch(err => {
                        EventBus.$emit('NOTIFY_ERROR');

                        if (err.response && err.response.data && err.response.data.errors) {
                            this.errors[key] = err.response.data.errors;
                        }

                        this.$forceUpdate();
                    });
            }
        },
        props: {
            translations: {
                type: Object,
                required: true
            },
            initCountries: {
                type: Array,
                required: true
            },
            routes: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
    .list-move {
        transition: transform .5s ease-in-out;
    }

    .list-enter-active, .list-leave-active {
        transition: all 1s;
    }
    .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
        opacity: 0;
        transform: translateY(30px);
    }
</style>