var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "d-flex flex-sm-row flex-column justify-content-between align-items-sm-center mb-sm-5 mb-4"
    },
    [
      _c("h2", { staticClass: "my-sm-0 mt-0 mb-3" }, [_vm._v("Blokken")]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "custom-dropdown",
          attrs: {
            "uk-dropdown":
              "mode: click; pos: bottom-right; animation: uk-animation-slide-top-small;"
          }
        },
        [
          _c(
            "ul",
            { staticClass: "uk-nav uk-dropdown-nav" },
            _vm._l(_vm.blocks, function(block) {
              return _c("li", [
                _c(
                  "a",
                  {
                    on: {
                      click: function($event) {
                        return _vm.add(block)
                      }
                    }
                  },
                  [_vm._v(_vm._s(block.name))]
                )
              ])
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "uk-button uk-button-primary" }, [
      _c("i", { staticClass: "fas fa-plus mr-2" }),
      _vm._v(" Toevoegen")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }