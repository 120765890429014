var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "side-menu" },
    [
      _vm.breadcrumbs.length > 0
        ? _c("li", [
            _c(
              "a",
              {
                staticClass: "category-back",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return (function() {
                      return _vm.change("")
                    })($event)
                  }
                }
              },
              [
                _c("span", [
                  _c("i", { staticClass: "fas fa-chevron-left mr-2" }),
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.translations.all_categories || "Alle categorieën"
                      )
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.breadcrumbs, function(breadcrumb, index) {
        return _c("li", [
          index !== _vm.breadcrumbs.length - 1
            ? _c(
                "a",
                {
                  staticClass: "category-back",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return (function() {
                        return _vm.change(breadcrumb)
                      })($event)
                    }
                  }
                },
                [
                  _c("span", [
                    _c("i", { staticClass: "fas fa-chevron-left mr-2" }),
                    _vm._v(" " + _vm._s(breadcrumb.name))
                  ])
                ]
              )
            : _c(
                "a",
                { staticClass: "category-header", attrs: { href: "#" } },
                [_vm._v(" " + _vm._s(breadcrumb.name))]
              )
        ])
      }),
      _vm._v(" "),
      _vm._l(_vm.links, function(link) {
        return _c("li", [
          link.products === 0
            ? _c(
                "a",
                {
                  class: { "category-item": _vm.breadcrumbs.length > 0 },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return (function() {
                        return _vm.change(link)
                      })($event)
                    }
                  }
                },
                [
                  _vm._v(_vm._s(link.name) + " "),
                  _c("font-awesome-icon", {
                    attrs: { icon: ["fas", "chevron-right"] }
                  })
                ],
                1
              )
            : _c(
                "a",
                {
                  class: { "category-item": _vm.breadcrumbs.length > 0 },
                  attrs: { href: link.route }
                },
                [_vm._v(_vm._s(link.name))]
              )
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }