var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c(
      "a",
      { ref: "trigger", staticClass: "uk-navbar-toggle", attrs: { href: "#" } },
      [
        _c("img", {
          attrs: {
            src: "/assets/images/icons/loupe.svg",
            draggable: "false",
            "uk-svg": "",
            height: "20"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }