var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c("multi-language-field", {
        attrs: {
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["title"]),
          label: "Titel"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("text-field", {
                  attrs: {
                    "init-errors": slotProps.errors.title || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".title"),
                    value: slotProps.title
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("title", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm._v(" "),
      _c("multi-language-field", {
        attrs: {
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["body"]),
          label: "Inhoud"
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", _vm.input)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("ckeditor-field", {
                  attrs: {
                    "init-errors": slotProps.errors.body || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".body"),
                    value: slotProps.body
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("body", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm._v(" "),
      _c("toggle-field", {
        attrs: {
          label: "Grote knoppen",
          name: "big",
          "init-errors": _vm.errors.big || []
        },
        model: {
          value: _vm.input.big,
          callback: function($$v) {
            _vm.$set(_vm.input, "big", $$v)
          },
          expression: "input.big"
        }
      }),
      _vm._v(" "),
      _c("repeater-field", {
        attrs: {
          types: ["Link"],
          "init-errors": _vm.errors.links,
          label: "Links",
          max: 3
        },
        scopedSlots: _vm._u([
          {
            key: "Link",
            fn: function(slotProps) {
              return [
                _c("multi-language-field", {
                  attrs: {
                    value: slotProps.input,
                    locales: _vm.locales,
                    label: "Label *",
                    "init-errors": slotProps.errors || {}
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput(null, value)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c("text-field", {
                              staticClass: "uk-margin-medium-bottom",
                              attrs: {
                                "init-errors": slotProps.errors.label || [],
                                name: _vm.dotToName(_vm.fieldPrefix + ".label"),
                                value: slotProps.label
                              },
                              on: {
                                input: function(value) {
                                  return slotProps.handleInput("label", value)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("multi-language-field", {
                  attrs: {
                    value: slotProps.input,
                    locales: _vm.locales,
                    label: "Url *",
                    "init-errors": slotProps.errors || {}
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput(null, value)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c("text-field", {
                              staticClass: "uk-margin-medium-bottom",
                              attrs: {
                                "init-errors": slotProps.errors.url || [],
                                name: _vm.dotToName(_vm.fieldPrefix + ".url"),
                                value: slotProps.url
                              },
                              on: {
                                input: function(value) {
                                  return slotProps.handleInput("url", value)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("div", { attrs: { "uk-grid": "" } }, [
                  _c(
                    "div",
                    { staticClass: "uk-width-1-2" },
                    [
                      _c(
                        "select-field",
                        {
                          attrs: {
                            label: "Type *",
                            name: "type",
                            required: "required",
                            value: slotProps.input.type || "primary",
                            "init-errors": (slotProps.errors || {}).type || []
                          },
                          on: {
                            input: function(value) {
                              return slotProps.handleInput("type", value)
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "primary" } }, [
                            _vm._v("Primair")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "secondary" } }, [
                            _vm._v("Secundair")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "tertiary" } }, [
                            _vm._v("Tertiair")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ])
              ]
            }
          }
        ]),
        model: {
          value: _vm.input.links,
          callback: function($$v) {
            _vm.$set(_vm.input, "links", $$v)
          },
          expression: "input.links"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }