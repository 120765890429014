var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c(
      "a",
      { attrs: { href: _vm.routes.overview } },
      [
        _c("img", {
          staticClass: "mr-2",
          attrs: {
            src: "/assets/images/icons/cart.svg",
            draggable: "false",
            "uk-svg": ""
          }
        }),
        _vm._v("\n        " + _vm._s(_vm.translations.cart) + "\n\n        "),
        _vm.cart.items.length > 0
          ? _c("badge", { staticClass: "cart-badge" }, [
              _vm._v(_vm._s(_vm.cart.items.length || 0))
            ])
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "cart-dropdown",
        attrs: {
          "uk-dropdown":
            "offset: 0; animation: uk-animation-slide-bottom-small; duration: 250;"
        }
      },
      [
        _c("h5", { staticClass: "mb-1" }, [
          _vm._v(_vm._s(_vm.translations.title))
        ]),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(_vm.cart.items, function(item) {
            return _c("li", [
              _c("p", { staticClass: "sku mb-0" }, [
                _vm._v(
                  _vm._s(_vm.translations.sku) +
                    ": " +
                    _vm._s(item.purchasable.external_code)
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex uk-width-1-1 justify-content-between align-items-start"
                },
                [
                  _c("h6", { staticClass: "my-0" }, [
                    _vm._v(
                      _vm._s(item.quantity) +
                        "x " +
                        _vm._s(item.purchasable.name)
                    )
                  ]),
                  _vm._v(" "),
                  _c("h6", { staticClass: "my-0" }, [
                    _vm._v(_vm._s(item.total_formatted))
                  ])
                ]
              )
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c("p", { staticClass: "uk-text-right mt-3 mb-4" }, [
          _c("strong", [_vm._v(_vm._s(_vm.translations.subtotal))]),
          _vm._v(" "),
          _c("span", { staticClass: "text--small mr-2" }, [
            _vm._v(_vm._s(_vm.translations.excl_vat))
          ]),
          _vm._v(" "),
          _c("strong", [
            _vm._v("\n            " + _vm._s(_vm.cart.subtotal_formatted))
          ])
        ]),
        _vm._v(" "),
        _vm.cart.items.length > 0
          ? _c(
              "div",
              { staticClass: "d-flex justify-content-end align-items-center" },
              [
                _c(
                  "a",
                  {
                    staticClass: "uk-link mr-4",
                    attrs: { href: _vm.routes.overview }
                  },
                  [_vm._v(_vm._s(_vm.translations.edit_cart))]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "uk-button uk-button-primary",
                    attrs: { href: _vm.routes.order }
                  },
                  [_vm._v(_vm._s(_vm.translations.order))]
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }