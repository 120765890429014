var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product" }, [
    _c("a", { attrs: { href: _vm.detailRoute } }, [
      _vm.imageDisplayMode == "cover"
        ? _c(
            "div",
            {
              staticClass: "product__image product__image--cover",
              attrs: { "data-src": _vm.firstImage, "uk-img": "" }
            },
            [
              _vm.product.discount > 0
                ? _c("div", { staticClass: "promo-banner" }, [
                    _vm._v(
                      "\n                -" +
                        _vm._s(_vm.product.discount_percentage) +
                        "%\n            "
                    )
                  ])
                : _vm._e()
            ]
          )
        : _c(
            "div",
            {
              staticClass: "product__image product__image--container",
              class: { "product__image--bordered": _vm.border }
            },
            [
              _c("img", {
                attrs: {
                  "data-src": _vm.firstImage,
                  alt: _vm.product.name,
                  draggable: "false",
                  "uk-img": ""
                }
              }),
              _vm._v(" "),
              _vm.product.discount > 0
                ? _c("div", { staticClass: "promo-banner" }, [
                    _vm._v(
                      "\n                -" +
                        _vm._s(_vm.product.discount_percentage) +
                        "%\n            "
                    )
                  ])
                : _vm._e()
            ]
          )
    ]),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.detailRoute } }, [
      _c("p", { staticClass: "product__sku" }, [
        _vm._v(
          _vm._s(_vm.translations.sku) +
            ": " +
            _vm._s(_vm.product.external_code)
        )
      ])
    ]),
    _vm._v(" "),
    _c("a", { attrs: { href: _vm.detailRoute } }, [
      _c("h3", { staticClass: "product__title" }, [
        _vm._v(_vm._s(_vm.product.name))
      ])
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "product__inputs" }, [
      _c(
        "label",
        {
          staticClass: "product__label mr-3",
          attrs: { for: "product-sizing" }
        },
        [_vm._v(_vm._s(_vm.translations.quantity) + ": ")]
      ),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.quantity,
            expression: "quantity",
            modifiers: { number: true }
          }
        ],
        staticClass: "product__input",
        attrs: {
          type: "number",
          name: "amount",
          min: "1",
          max: parseInt(_vm.product.available_stock),
          value: "1"
        },
        domProps: { value: _vm.quantity },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.quantity = _vm._n($event.target.value)
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      })
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "product__footer mt-4" }, [
      _c(
        "span",
        { staticClass: "d-flex flex-column align-items-start" },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-baseline" },
            [
              _c("price", {
                staticClass: "product__price mr-2 my-0",
                attrs: { price: _vm.product.price_formatted }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "text--small mr-3 my-0" }, [
                _vm._v("/ " + _vm._s(_vm.product.unit || _vm.translations.unit))
              ]),
              _vm._v(" "),
              _vm.product.discount > 0
                ? _c("p", { staticClass: "product__discount my-0" }, [
                    _vm._v(_vm._s(_vm.translations.sales_price) + ": "),
                    _c("span", [
                      _vm._v(
                        "€ " +
                          _vm._s(
                            _vm.format_price(
                              _vm.product.original_price_formatted
                            )
                          )
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("stock-indication", {
            attrs: {
              stock: _vm.product.stock,
              translations: _vm.translations.stock || {},
              threshold: _vm.stockThreshold,
              date: _vm.product.restock_date_formatted || null
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "a",
            {
              staticClass: "favorite-button",
              on: { click: _vm.toggleFavorites }
            },
            [
              _c("font-awesome-icon", {
                staticClass:
                  "uk-text-large uk-text-middle uk-margin-small-right",
                attrs: { icon: [_vm.inFavorites ? "fas" : "far", "heart"] }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.showProduct(_vm.product)
            ? _c(
                "add-to-cart-button",
                {
                  staticClass: "button__cart",
                  attrs: {
                    quantity: _vm.quantity,
                    route: _vm.routes.addToCart,
                    "purchasable-type": _vm.product.purchasable_type,
                    "purchasable-id": _vm.product.id
                  }
                },
                [
                  _c("img", {
                    attrs: {
                      src: "/assets/images/icons/cart.svg",
                      draggable: "false",
                      "uk-svg": ""
                    }
                  })
                ]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }