var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "uk-section uk-section-small pt-0" }, [
      _c("div", { staticClass: "products-bar" }, [
        _c("section", [
          _c("p", { staticClass: "my-sm-0 mb-2" }, [
            _c("strong", [
              _vm._v(_vm._s(_vm.elementsTotal || _vm.products.length))
            ]),
            _vm._v(" " + _vm._s(_vm.translations.results))
          ])
        ]),
        _vm._v(" "),
        _c("section", [
          _vm.sorting
            ? _c("p", { staticClass: "my-0 mr-sm-4 mr-2 text--small" }, [
                _vm._v(_vm._s(_vm.translations.sort) + ":")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.sorting
            ? _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sort,
                      expression: "sort"
                    }
                  ],
                  staticClass: "products-bar__sorting",
                  attrs: { name: "product_sorting" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.sort = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.handleSort
                    ]
                  }
                },
                _vm._l(_vm.sorting, function(method, key) {
                  return _c("option", { domProps: { value: key } }, [
                    _vm._v(_vm._s(_vm.translations.sorting[method.lang_key]))
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.styleTypes.length > 1
            ? _c(
                "div",
                _vm._l(_vm.styleTypes, function(type, index) {
                  return _c(
                    "a",
                    {
                      staticClass: "products-bar__toggle noselect ml-4",
                      class: {
                        "products-bar__toggle--active": _vm.styleType == index
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.styleType = index
                        }
                      }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/assets/images/icons/products-" + type + ".svg",
                          draggable: "false",
                          "uk-svg": ""
                        }
                      })
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "uk-section uk-section-small pt-0" }, [
      _vm.loading
        ? _c("div", { staticClass: "uk-width-1-1 uk-flex uk-flex-center" }, [
            _c("div", { attrs: { "uk-spinner": "ratio: 2.5" } })
          ])
        : _c(
            "div",
            { class: "products-" + _vm.styleTypes[_vm.styleType] },
            _vm._l(_vm.products, function(item) {
              return _c(
                _vm.component + "-" + _vm.styleTypes[_vm.styleType],
                _vm._b(
                  {
                    key: item.id,
                    tag: "div",
                    attrs: { product: item },
                    on: {
                      "list-updated": function(list) {
                        return (_vm.products = list)
                      }
                    }
                  },
                  "div",
                  _vm.passThroughProps,
                  false
                )
              )
            }),
            1
          )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }