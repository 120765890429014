<template>
    <div class="product">
        <a :href="detailRoute">
            <div v-if="imageDisplayMode == 'cover'" class="product__image product__image--cover" :data-src="firstImage" uk-img>
                <div class="promo-banner" v-if="product.discount > 0">
                    -{{ product.discount_percentage }}%
                </div>
            </div>
            <div v-else class="product__image product__image--container" :class="{'product__image--bordered': border}">
                <img :data-src="firstImage" :alt="product.name" draggable="false" uk-img>

                <div class="promo-banner" v-if="product.discount > 0">
                    -{{ product.discount_percentage }}%
                </div>
            </div>
        </a>
        <a :href="detailRoute">
            <p class="product__sku">{{ translations.sku }}: {{ product.external_code }}</p>
        </a>
        <a :href="detailRoute">
            <h3 class="product__title">{{ product.name }}</h3>
        </a>

        <!--<span class="product__inputs">-->
            <!--<label for="product-sizing" class="product__label mr-3">Maat: </label>-->
            <!--<select name="product-sizing" class="product__select">-->
                <!--<option value="default" selected disabled>Kies een maat</option>-->
                <!--<option value="x-small">XS</option>-->
                <!--<option value="small">S</option>-->
                <!--<option value="medium">M</option>-->
                <!--<option value="large">L</option>-->
                <!--<option value="x-large">XL</option>-->
            <!--</select>-->
        <!--</span>-->

        <span class="product__inputs">
            <label for="product-sizing" class="product__label mr-3">{{ translations.quantity }}: </label>

            <input v-model.number="quantity" type="number" name="amount" class="product__input" min="1" :max="parseInt(product.available_stock)" value="1">
        </span>

        <span class="product__footer mt-4">
            <span class="d-flex flex-column align-items-start">
                <div class="d-flex align-items-baseline">
                    <price :price="product.price_formatted" class="product__price mr-2 my-0"></price>
                    <p class="text--small mr-3 my-0">/ {{ product.unit || translations.unit }}</p>

                    <p v-if="product.discount > 0" class="product__discount my-0">{{ translations.sales_price }}: <span>&euro; {{ format_price(product.original_price_formatted) }}</span></p>
                </div>

                <stock-indication
                    :stock="product.stock"
                    :translations="translations.stock || {}"
                    :threshold="stockThreshold"
                    :date="product.restock_date_formatted || null"
                ></stock-indication>
            </span>
            <div>
                <a class="favorite-button" @click="toggleFavorites">
                    <font-awesome-icon class="uk-text-large uk-text-middle uk-margin-small-right" :icon="[inFavorites ? 'fas' : 'far', 'heart']" />
                </a>
                <add-to-cart-button
                    v-if="showProduct(product)"
                    class="button__cart"
                    :quantity="quantity"
                    :route="routes.addToCart"
                    :purchasable-type="product.purchasable_type"
                    :purchasable-id="product.id"
                >
                    <img src="/assets/images/icons/cart.svg" draggable="false" uk-svg>
                </add-to-cart-button>
            </div>
        </span>
    </div>
</template>

<script>
    import { format_price } from '../functions.js';
    import StockIndication from "./StockIndication";
    import { EventBus } from "../EventBus";

    export default {
        name: "ProductItemGrid",
        components: {StockIndication},
        computed: {
            detailRoute() {
                return this.routes.detail.replace('-id-', this.product.id)
            },
            firstImage() {
                return this.product?.images[0]?.thumb ?? this.product?.images[0]?.url ?? '';
            }
        },
        methods: {
            format_price,
            toggleFavorites() {
                axios.put(this.routes.toggleFavorites.replace('-id-', this.product.id))
                    .then(res => {
                        if (res?.data?.message) {
                            EventBus.$emit('NOTIFY', res.data.message);
                        }

                        this.inFavorites = !this.inFavorites;
                    });
            },
            showProduct(product) {
                if (product.stock > 0) {
                    return true;
                }

                return !!this.allowBackorder;
            }
        },
        data() {
            return {
                quantity: 1,
                inFavorites: this.product.in_favorites ?? false
            }
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            translations: {
                type: Object,
                required: true
            },
            border: {
                type: Boolean,
                required: false,
                default: true
            },
            stockThreshold: {
                type: Number,
                required: false,
                default: 5
            },
            imageDisplayMode: {
                type: String,
                required: false,
                default: 'contain'
            },
            routes: {
                required: true,
                type: Object
            },
            allowBackorder: {
                required: false,
                default: true
            }
        }
    }
</script>

<style scoped>

</style>
