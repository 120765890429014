<template>
    <form>
        <multi-language-field
            :locales="locales"
            v-model="input"
            :init-errors="only(errors, ['title'])"
            label="Titel"
        >
            <template v-slot:default="slotProps">
                <text-field
                    :init-errors="slotProps.errors.title || []"
                    :name="dotToName(`${fieldPrefix}.title`)"
                    :value="slotProps.title"
                    @input="value => slotProps.handleInput('title', value)"
                ></text-field>
            </template>
        </multi-language-field>

        <multi-language-field
            :locales="locales"
            v-model="input"
            :init-errors="only(errors, ['body'])"
            label="Intro"
        >
            <template v-slot:default="slotProps">
                <textarea-field
                    :init-errors="slotProps.errors.body || []"
                    :name="dotToName(`${fieldPrefix}.body`)"
                    :value="slotProps.body"
                    @input="value => slotProps.handleInput('body', value)"
                ></textarea-field>
            </template>
        </multi-language-field>

        <multi-language-field
            :locales="locales"
            v-model="input"
            :init-errors="only(errors, ['table'])"
            label="Tabel"
        >
            <template v-slot:default="slotProps">
                <ckeditor-field
                    :custom-toolbar="[['Table']]"
                    :init-errors="slotProps.errors.table || []"
                    :name="dotToName(`${fieldPrefix}.table`)"
                    :value="slotProps.table"
                    @input="value => slotProps.handleInput('table', value)"
                ></ckeditor-field>
            </template>
        </multi-language-field>
    </form>
</template>

<script>
    import TextField from "../Fields/TextField";
    import { dotToName } from "../../functions";

    export default {
        name: "TableBlock",
        components: {TextField},
        data() {
            return {
                block: this.initBlock,
                input: this.initInput,
                errors: this.initErrors,
            }
        },
        methods: {
            dotToName: dotToName,
            only(object, keys = []) {
                return _.mapValues(object, lang => {
                    return _.pick(lang, keys);
                });
            },
        },
        watch: {
            initErrors(newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            initBlock: {
                type: Object,
                required: true
            },
            fieldPrefix: {
                type: String,
                required: false,
                default: ''
            },
            initInput: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            initErrors: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            locales: {
                type: Object,
                required: false,
                default: () => { return []; }
            },
            routes: {
                type: Object,
                required: false,
                default: () => { return {} }
            }
        }
    }
</script>