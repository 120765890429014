var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label !== null && _vm.label !== ""
      ? _c("div", [
          _c("label", { staticClass: "mr-sm-4 mb-3 form-custom__label" }, [
            _vm._v(_vm._s(_vm.label))
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "tabs-nav uk-margin-small-top",
        attrs: { "uk-switcher": "connect: .locale-switcher-" + _vm._uid }
      },
      _vm._l(_vm.locales, function(locale, shortcode) {
        return _c("li", { staticClass: "uk-active" }, [
          _c("a", { attrs: { href: "" } }, [
            _vm._v(_vm._s(locale.name) + " "),
            !_vm.empty(_vm.errors[shortcode])
              ? _c("span", [
                  _c("i", { staticClass: "fa fa-exclamation-circle" })
                ])
              : _vm._e()
          ])
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "ul",
      {
        staticClass: "uk-switcher mt-1 mb-4",
        class: "locale-switcher-" + _vm._uid
      },
      _vm._l(_vm.locales, function(locale, shortcode) {
        return _c(
          "li",
          [
            _vm._t(
              "default",
              null,
              {
                locale: shortcode,
                errors: _vm.errors[shortcode] || [],
                handleInput: function(prop, value) {
                  return _vm.handleInput(shortcode, prop, value)
                }
              },
              _vm.input[shortcode]
            )
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }