
export const dotToName = (key) => {
    // Split by dot
    let segments = key.split(".");

    // If only 1 segment is found return it or just return the whole key.
    if (segments.length < 2) {
        return segments[0] ?? key;
    }

    // Start with the first segment
    let str = segments[0];

    // Loop over the other segments and ad square brackets unless the segment is a * (for non-associative arrays)
    for (let i = 1; i < segments.length; i++) {
        if (segments[i] === '*') {
            str += '[]';
        } else {
            str += `[${segments[i]}]`;
        }
    }

    return str;
};

export const format_price = (price) => {
    let p =_.split(price, ',')[0];
    const deci = _.split(price, ',')[1];
    if (deci == '0' || deci == '' || deci == '00' || deci == null) {
        p = `${p},-`;
    } else {
        p = `${p},${deci}`;
    }

    return p;
};

export const changeUrl = (url, param, paramVal) => {
    var TheAnchor = null;
    var newAdditionalURL = "";
    var tempArray = url.split("?");
    var baseURL = tempArray[0];
    var additionalURL = tempArray[1];
    var temp = "";

    if (additionalURL)
    {
        var tmpAnchor = additionalURL.split("#");
        var TheParams = tmpAnchor[0];
        TheAnchor = tmpAnchor[1];
        if(TheAnchor)
            additionalURL = TheParams;

        tempArray = additionalURL.split("&");

        for (var i=0; i<tempArray.length; i++)
        {
            if(tempArray[i].split('=')[0] != param)
            {
                newAdditionalURL += temp + tempArray[i];
                temp = "&";
            }
        }
    }
    else
    {
        var tmpAnchor = baseURL.split("#");
        var TheParams = tmpAnchor[0];
        TheAnchor  = tmpAnchor[1];

        if(TheParams)
            baseURL = TheParams;
    }

    if(TheAnchor)
        paramVal += "#" + TheAnchor;

    var rows_txt = temp + "" + param + "=" + paramVal;
    return baseURL + "?" + newAdditionalURL + rows_txt;
};

export const confirm = (question, callback) => {
    const content = `
        <div id="modal-center" class="uk-flex-top image-modal" uk-modal="sel-close: > div > #closer;">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <span class="content">
                    <h2>${window?.translations?.modals?.confirm['are-you-sure'] || ''}</h2>
                    <p>${question}</p>

                    <div class="d-flex align-items-center">
                        <a class="uk-button uk-button-primary mr-4 js-ok-button">${ window?.translations?.modals?.confirm?.ok }</a>
                        <a href="#" class="uk-button uk-button-secondary js-cancel-button">${ window?.translations?.modals?.confirm?.cancel }</a>
                    </div>
                </span>
                <button id="closer"><i class="fas fa-times"></i></button>
            </div>
        </div>
    `;

    const modal = UIkit.modal(content);
    modal.$el.querySelector('.js-ok-button')
        .addEventListener('click', () => {
            callback();
            modal.hide();
        });

    modal.$el.querySelector('.js-cancel-button')
        .addEventListener('click', () => modal.hide());

    modal.show();
};

export const outerHeight = (el) => {
    var height = el.offsetHeight;
    var style = getComputedStyle(el);

    height += parseInt(style.marginTop) + parseInt(style.marginBottom);
    return height;
};