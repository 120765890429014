<template>
    <div>
        <div v-if="cart.items.length < 1">
            <p>{{ translations.cart_empty }}</p>
            <a href="/" class="uk-link"><i class="fas fa-arrow-left mr-3"></i> {{ translations.continue_shopping }}</a>
        </div>

        <cart-overview-row-component
            v-for="item in cart.items":key="item.id"
            :item="item"
            :translations="translations"
            :routes="routes"
            :stock-threshold="stockThreshold"
        >
        </cart-overview-row-component>
    </div>
</template>

<script>
    import CartOverviewRowComponent from "./CartOverviewRowComponent";
    import { EventBus } from "../EventBus";

    export default {
        name: "CartOverviewComponent",
        components: {CartOverviewRowComponent},
        mounted() {
            EventBus.$on('CART_UPDATED', cart => {
                this.cart = cart;
            });
        },
        data() {
            return {
                cart: this.initCart
            }
        },
        props: {
            initCart: {
                type: Object,
                required: true
            },
            translations: {
                type: Object,
                required: true
            },
            routes: {
                type: Object,
                required: true
            },
            stockThreshold: {
                type: Number,
                required: false,
                default: 5
            }
        }
    }
</script>

<style scoped>

</style>