var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.content,
            expression: "content"
          }
        ],
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.content },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.content = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("ckeditor", {
        attrs: {
          type: _vm.type,
          editorUrl: _vm.editorUrl,
          config: _vm.config,
          "tag-name": _vm.tagName,
          "read-only": _vm.readOnly
        },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }