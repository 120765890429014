<template>
    <li>
        <a :href="routes.overview">
            <img src="/assets/images/icons/cart.svg" class="mr-2" draggable="false" uk-svg>
            {{ translations.cart }}

            <badge v-if="cart.items.length > 0" class="cart-badge">{{ cart.items.length || 0}}</badge>
        </a>

        <div uk-dropdown="offset: 0; animation: uk-animation-slide-bottom-small; duration: 250;"
             class="cart-dropdown">
            <h5 class="mb-1">{{ translations.title }}</h5>

            <ul>
                <li v-for="item in cart.items">
                    <p class="sku mb-0">{{ translations.sku }}: {{ item.purchasable.external_code }}</p>

                    <div class="d-flex uk-width-1-1 justify-content-between align-items-start">
                        <h6 class="my-0">{{ item.quantity }}x {{ item.purchasable.name }}</h6>
                        <h6 class="my-0">{{ item.total_formatted }}</h6>
                    </div>

                    <!--<p class="text&#45;&#45;small mb-0 mt-1">Maat: X-Large</p>-->
                </li>
            </ul>

            <p class="uk-text-right mt-3 mb-4">
                <strong>{{ translations.subtotal }}</strong> <span class="text--small mr-2">{{ translations.excl_vat }}</span> <strong>
                {{ cart.subtotal_formatted }}</strong>
            </p>

            <div class="d-flex justify-content-end align-items-center" v-if="cart.items.length > 0">
                <a :href="routes.overview" class="uk-link mr-4">{{ translations.edit_cart }}</a>
                <a :href="routes.order" class="uk-button uk-button-primary">{{ translations.order }}</a>
            </div>
        </div>
    </li>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "CartDropdown",
        mounted() {
            EventBus.$on('CART_UPDATED', cart => {
                this.cart = cart;
            });
        },
        data() {
            return {
                cart: this.initCart
            }
        },
        props: {
            translations: {
                type: Object,
                required: true
            },
            initCart: {
                type: Object,
                required: true
            },
            routes: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>