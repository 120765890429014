<template>
    <div v-if="cart.items.length > 0">
        <div class="cart__footer">
            <div class="cart__footer__delivery">
                <div class="uk-width-1-1@s uk-width-3-5@m cart__footer__delivery--title">
                    <label for="dropshipping" class="mb-2 uk-display-block uk-text-bold">{{ translations.dropshipping }}</label>
                    <toggle-input @input="updateDropshipping" name="dropshipping" v-model="dropshipping"></toggle-input>
                </div>
                <form class="form-custom uk-form" uk-grid v-if="cart.dropshipping">
                    <div class="uk-width-1-1@s uk-width-3-5@m mt-3" >
                        <label for="dropshipping" class="mb-2 uk-display-block uk-text-bold">{{ translations.country }}</label>
                        <select @change="updateDropshipping" class="uk-select" v-model.number="dropshipping_country_id" name="dropshipping_country_id" id="dropshipping_country_id">
                            <option disabled value="">{{ translations.pickachoice }}</option>
                            <option v-for="(shortcode, id) in countries" :value="id">{{ translations.countries[shortcode] }}</option>
                        </select>
                    </div>
                </form>
            </div>

            <div class="cart__footer__totals">
                <div>
                    <p class="my-0">{{ translations.subtotal }} <span class="text--small"></span></p>
                </div>
                <div>
                    <h3 class="my-0">&euro; {{ cart.subtotal_formatted }}</h3>
                </div>
                <div v-if="cart.discount > 0">
                    <p class="my-0">{{ translations.discount }} <span class="text--small"></span></p>
                </div>
                <div v-if="cart.discount > 0">
                    <h3 class="my-0">- &euro; {{ cart.discount_formatted }}</h3>
                </div>
                <div>
                    <p class="my-0">{{ translations.vat }}</p>
                </div>
                <div>
                    <h3 class="my-0">&euro; {{ cart.vat_formatted }}</h3>
                </div>
                <div>
                    <p class="my-0">{{ translations.shipping_costs }}</p>
                </div>
                <div>
                    <h3 class="my-0 uk-text-small" v-if="cart.shipping_type === 'after'">{{ translations.payment_after }}</h3>
                    <h3 class="my-0" v-else-if="cart.shipping_cost === 0">&euro; 0,-</h3>
                    <h3 class="my-0" v-else>&euro; {{ cart.shipping_cost_formatted}}</h3>
                </div>
                <div>
                    <h5 class="my-0">{{ translations.total }}</h5>
                </div>
                <div>
                    <h2 class="my-0 uk-text-primary">&euro; {{ cart.total_formatted }}</h2>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mt-5">
            <a href="/" class="uk-link"><i class="fas fa-arrow-left mr-3"></i> {{ translations.continue_shopping }}</a>
            <a :href="routes.finish" class="uk-button uk-button-primary">{{ translations.finish }}</a>
        </div>
    </div>

</template>

<script>
    import { EventBus } from "../EventBus";
    import DatePicker from "./DatePicker";

    export default {
        name: "CartOverviewTotalsComponent",
        components: {DatePicker},
        mounted() {
            EventBus.$on('CART_UPDATED', cart => {
                this.cart = cart;
            });
        },
        data() {
            return {
                cart: this.initCart,
                delivery_method: this.initCart.delivery_type ?? this.getDefaultMethod(),
                delivery_date: this.initCart.delivery_date_formatted ?? null,
                dropshipping: this.initCart.dropshipping,
                dropshipping_country_id: ''
            };
        },
        methods: {
            updateDropshipping() {
                axios.post(this.routes.patch, {dropshipping: this.dropshipping, _method: 'PATCH', dropshipping_country_id: this.dropshipping_country_id})
                    .then(res => {
                        if (res?.data?.cart) {
                            EventBus.$emit('CART_UPDATED', res.data.cart);
                        }
                    });
            },
            getDefaultMethod() {
                return _.keys(this.deliveryMethods)[0];
            }
        },
        props: {
            initCart: {
                type: Object,
                required: true
            },
            translations: {
                type: Object,
                required: true
            },
            deliveryMethods: {
                type: Object,
                required: true
            },
            countries: {
                type: Object,
                required: false,
                default() { return {}; }
            },
            routes: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>