<template>
    <div>
        <slot v-if="slotToShow === 'content'" name="content"></slot>

        <slot v-if="slotToShow === 'product_list'" name="product_list"></slot>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "SlotToggle",
        mounted() {
            EventBus.$on('TOGGLE_SLOT', slot => {
                this.slotToShow = slot;
            });
        },
        data() {
            return {
                slotToShow: this.defaultSlot
            }
        },
        props: {
            defaultSlot: {
                type: String,
                default: 'content'
            }
        }
    }
</script>

<style scoped>

</style>