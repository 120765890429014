var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c("div", { staticClass: "js-upload", attrs: { "uk-form-custom": "" } }, [
        _c("input", { ref: "input", attrs: { type: "file" } }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-default",
            attrs: { type: "button", tabindex: "-1" }
          },
          [_vm._v("Select")]
        ),
        _vm._v(" "),
        _vm.uploading ? _c("div", { attrs: { "uk-spinner": "" } }) : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c("td", [
      _vm.error
        ? _c("p", { staticClass: "uk-text-danger" }, [
            _vm._v(_vm._s(_vm.error))
          ])
        : _vm.file
        ? _c("p", [_vm._v(_vm._s(_vm.file.file_name))])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("td", [
      _vm.file
        ? _c("div", { staticClass: "uk-flex" }, [
            _c(
              "a",
              {
                staticClass: "action-button action-button-primary",
                attrs: { href: _vm.file.url, download: "", target: "_blank" }
              },
              [_c("i", { staticClass: "fa fa-download" })]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass:
                  "action-button action-button-primary uk-margin-small-left",
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.deleteFile(_vm.file)
                  }
                }
              },
              [_c("i", { staticClass: "fa fa-trash" })]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }