var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content === null
    ? _c(_vm.tag, { tag: "component" }, [_vm._t("default")], 2)
    : _c(_vm.tag, { tag: "component" }, [
        _vm._v("\n    " + _vm._s(_vm.content) + "\n")
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }