<template>
    <div class="uk-form-controls">
        <div class="uk-width-1-1 uk-margin-small-bottom" v-if="label">
            <label :for="id || `${_uid}-${name}`" class="mr-sm-4 mb-sm-0 mb-3 form-custom__label">{{ label }}</label>
        </div>
        <select name="" id=""
            class="uk-input"
            :class="{'uk-form-danger': errors.length > 0}"
            :id="id || `${_uid}-${name}`"
            :name="name"
            :required="required"
            :placeholder="placeholder"
            v-model="val"
            @change="$emit('input', val)"
        >
            <slot></slot>
        </select>
        <p
            v-if="errors.length > 0"
            class="text--small uk-form-danger mt-2"
        >
            <strong>{{ errors[0] }}</strong>
        </p>
    </div>
</template>

<script>
    export default {
        name: "SelectField",
        data() {
            return {
                val: this.value,
                errors: this.initErrors
            }
        },
        watch: {
            initErrors (newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            name: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: false,
            },
            label: {
                type: String,
                required: false,
                default: null
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            placeholder: {
                type: String,
                required: false,
                default: ''
            },
            value: {
                type: String,
                required: false,
                default: ''
            },
            initErrors: {
                type: Array,
                required: false,
                default: () => { return []; }
            }
        }
    }
</script>

<style scoped>

</style>