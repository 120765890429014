var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "datepicker",
    class: _vm.className,
    attrs: { type: "text", name: _vm.name, id: _vm.id, autocomplete: "off" },
    domProps: { value: _vm.value },
    on: {
      input: function(e) {
        return _vm.handleInput(e)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }