<template>
    <div :uk-tooltip="!withText ? note : ''" v-if="stock > threshold" :class="{'product__stock product__stock--success': mode === 'default', 'status status--success': mode === 'compact'}">
        <i class="fas fa-check-circle" :class="{'mr-2': mode === 'default' }"></i>
        <p v-if="withText" class="my-0">{{ note }}</p>
    </div>

    <div :uk-tooltip="!withText ? note : ''" v-else-if="stock > 0 || ((!empty(dayRange) || !empty(date)) && stock >= 0)" :class="{'product__stock product__stock--warning': mode === 'default', 'status status--warning': mode === 'compact'}">
        <i class="fas fa-check-circle" :class="{'mr-2': mode === 'default' }"></i>
        <p v-if="withText" class="my-0">{{ note }}</p>
    </div>

    <div :uk-tooltip="!withText ? note : translations.contact_us_for_delivery_date" v-else :class="{'product__stock product__stock--danger': mode === 'default', 'status status--danger': mode === 'compact'}">
        <i class="fas fa-times-circle mr-2"></i>
        <p v-if="withText" class="my-0">{{ note }}</p>
    </div>
</template>

<script>
    export default {
        name: "StockIndication",
        computed: {
            dayRangeText() {
                if (!this.dayRange) {
                    return null;
                }

                const str = this.translations.day_range || 'Levering: :min-:max werkdagen';
                return str
                    .replace(':min', this.dayRange[0])
                    .replace(':max', this.dayRange[1]);
            },
            dateText() {
                const str = this.translations.date || 'Uiterlijk :date in huis';
                return str.replace(':date', this.date);
            },
            note() {
                if (this.stock > this.threshold) {
                    return this.translations.in_stock || 'Op voorraad';
                }

                if (this.stock > 0 || ((!this.empty(this.date) || !this.empty(this.dayRange)) && this.stock <= 0)) {
                    if (this.dayRange !== null && this.stock < 1) {
                        return this.dayRangeText
                    } else if ((this.date !== null && this.date !== '') && this.stock < 1) {
                        return this.dateText;
                    } else {
                        return this.translations.limited_stock || 'Beperkte voorraad'
                    }
                }

                return this.translations.out_of_stock || 'Niet voorradig';
            }
        },
        methods: {
            empty(val) {
                return (val == null || val === '');
            }
        },
        props: {
            translations: {
                type: Object,
                required: true
            },
            stock: {
                type: Number,
                required: true
            },
            threshold: {
                type: Number,
                required: false,
                default: 5
            },
            dayRange: {
                type: Array,
                required: false,
                default: null
            },
            date: {
                type: String,
                required: false,
                default: null
            },
            mode: {
                type: String,
                default: 'default'
            },
            withText: {
                default: true
            }
        }
    }
</script>

<style scoped>

</style>