<template>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "Notifications",
        mounted() {
            if (this.init) {
                this.notify(this.init.message, this.init.status, this.init.position, this.init.timeout);
            }

            EventBus.$on('NOTIFY', notification => {
                this.notify(notification.message, notification.status, notification.position, notification.timeout);
            });

            EventBus.$on('NOTIFY_ERROR', notification => {
                if (notification) {
                    this.notify(notification.message, notification.status, notification.position, notification.timeout);
                } else {
                    this.notify(this.error, 'danger');
                }
            });
        },
        methods: {
            notify(message, status = 'primary', pos = 'top-right', timeout = 3000) {
                UIkit.notification({ message, status, pos, timeout });
            }
        },
        props: {
            error: {
                type: String,
            },
            init: {
                type: Object,
                required: false,
                default: null
            }
        }
    }
</script>

<style scoped>

</style>