<template>
    <li>
        <a href="#" ref="trigger" class="uk-navbar-toggle">
            <img src="/assets/images/icons/loupe.svg" draggable="false" uk-svg height="20">
        </a>
    </li>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "SearchTriggerMobile",
        mounted() {
            this.trigger = UIkit.toggle(this.$refs.trigger, {
                target: '.nav-overlay',
                animation: 'uk-animation-fade'
            });

            UIkit.util.on(this.$refs.trigger, 'hide', function() {
                EventBus.$emit('HIDE_SEARCH');
            });

            UIkit.util.on(this.$refs.trigger, 'show', function() {
                EventBus.$emit('SHOW_SEARCH');
            });
        }
    }
</script>

<style scoped>

</style>