var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-custom" }, [
    _c(
      "table",
      {
        staticClass:
          "uk-table uk-table-middle uk-table-divider uk-table-justify uk-table-hover uk-table-responsive"
      },
      [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.translations.name))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.translations.shortcode))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.translations.franco_amount))]),
            _vm._v(" "),
            _c("th", [_vm._v(_vm._s(_vm.translations.shipping_cost))]),
            _vm._v(" "),
            _c("th", [
              _vm._v(_vm._s(_vm.translations.dropshipping_franco_amount))
            ]),
            _vm._v(" "),
            _c("th", [
              _vm._v(_vm._s(_vm.translations.dropshipping_shipping_cost))
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "transition-group",
          { attrs: { name: "list", tag: "tbody" } },
          [
            _vm._l(_vm.active, function(country, key) {
              return _c("tr", { key: country.id, staticClass: "list-item" }, [
                _c("td", [_vm._v(_vm._s(country.name))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(country.shortcode))]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    staticClass: "uk-input",
                    attrs: { type: "number", step: "0.01" },
                    domProps: { value: _vm.format(country.franco_amount) },
                    on: {
                      change: function(event) {
                        return _vm.changeNumberHandler(
                          event.currentTarget.value,
                          country.id,
                          "franco_amount"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors[country.id] &&
                  _vm.errors[country.id]["franco_amount"]
                    ? _c("span", { staticClass: "uk-text-danger" }, [
                        _vm._v(_vm._s(_vm.errors[key]["franco_amount"][0]))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    staticClass: "uk-input",
                    attrs: { type: "number", step: "0.01" },
                    domProps: { value: _vm.format(country.shipping_cost) },
                    on: {
                      change: function(event) {
                        return _vm.changeNumberHandler(
                          event.currentTarget.value,
                          country.id,
                          "shipping_cost"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors[country.id] && _vm.errors[country.id].shipping_cost
                    ? _c("span", { staticClass: "uk-text-danger" }, [
                        _vm._v(_vm._s(_vm.errors[key].shipping_cost[0]))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    staticClass: "uk-input",
                    attrs: { type: "number", step: "0.01" },
                    domProps: {
                      value: _vm.format(country.dropshipping_franco_amount)
                    },
                    on: {
                      change: function(event) {
                        return _vm.changeNumberHandler(
                          event.currentTarget.value,
                          country.id,
                          "dropshipping_franco_amount"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors[country.id] &&
                  _vm.errors[country.id].dropshipping_franco_amount
                    ? _c("span", { staticClass: "uk-text-danger" }, [
                        _vm._v(
                          _vm._s(_vm.errors[key].dropshipping_franco_amount[0])
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    staticClass: "uk-input",
                    attrs: { type: "number", step: "0.01" },
                    domProps: {
                      value: _vm.format(country.dropshipping_shipping_cost)
                    },
                    on: {
                      change: function(event) {
                        return _vm.changeNumberHandler(
                          event.currentTarget.value,
                          country.id,
                          "dropshipping_shipping_cost"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors[country.id] &&
                  _vm.errors[country.id].dropshipping_shipping_cost
                    ? _c("span", { staticClass: "uk-text-danger" }, [
                        _vm._v(
                          _vm._s(_vm.errors[key].dropshipping_shipping_cost[0])
                        )
                      ])
                    : _vm._e()
                ])
              ])
            }),
            _vm._v(" "),
            _vm._l(_vm.inactive, function(country, key) {
              return _c("tr", { key: country.id, staticClass: "list-item" }, [
                _c("td", [_vm._v(_vm._s(country.name))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(country.shortcode))]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    staticClass: "uk-input",
                    attrs: { type: "number", step: "0.01" },
                    domProps: { value: _vm.format(country.franco_amount) },
                    on: {
                      change: function(event) {
                        return _vm.changeNumberHandler(
                          event.currentTarget.value,
                          country.id,
                          "franco_amount"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors[country.id] &&
                  _vm.errors[country.id]["franco_amount"]
                    ? _c("span", { staticClass: "uk-text-danger" }, [
                        _vm._v(_vm._s(_vm.errors[key]["franco_amount"][0]))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    staticClass: "uk-input",
                    attrs: { type: "number", step: "0.01" },
                    domProps: { value: _vm.format(country.shipping_cost) },
                    on: {
                      change: function(event) {
                        return _vm.changeNumberHandler(
                          event.currentTarget.value,
                          country.id,
                          "shipping_cost"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors[country.id] && _vm.errors[country.id].shipping_cost
                    ? _c("span", { staticClass: "uk-text-danger" }, [
                        _vm._v(_vm._s(_vm.errors[key].shipping_cost[0]))
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    staticClass: "uk-input",
                    attrs: { type: "number", step: "0.01" },
                    domProps: {
                      value: _vm.format(country.dropshipping_franco_amount)
                    },
                    on: {
                      change: function(event) {
                        return _vm.changeNumberHandler(
                          event.currentTarget.value,
                          country.id,
                          "dropshipping_franco_amount"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors[country.id] &&
                  _vm.errors[country.id].dropshipping_franco_amount
                    ? _c("span", { staticClass: "uk-text-danger" }, [
                        _vm._v(
                          _vm._s(_vm.errors[key].dropshipping_franco_amount[0])
                        )
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("input", {
                    staticClass: "uk-input",
                    attrs: { type: "number", step: "0.01" },
                    domProps: {
                      value: _vm.format(country.dropshipping_shipping_cost)
                    },
                    on: {
                      change: function(event) {
                        return _vm.changeNumberHandler(
                          event.currentTarget.value,
                          country.id,
                          "dropshipping_shipping_cost"
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors[country.id] &&
                  _vm.errors[country.id].dropshipping_shipping_cost
                    ? _c("span", { staticClass: "uk-text-danger" }, [
                        _vm._v(
                          _vm._s(_vm.errors[key].dropshipping_shipping_cost[0])
                        )
                      ])
                    : _vm._e()
                ])
              ])
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }