<template>
    <div class="uk-width-1-1">
        <div uk-grid>
            <div v-if="shipping_method === 'SHIPPING'" class="uk-width-1-1">
                <div uk-grid>
                    <input type="hidden" name="shipping_address" v-model="shipping_address">

                    <div class="uk-width-1-1 mt-5">
                        <h3 class="uk-text-primary">{{ cart.dropshipping ? (translations.delivery_address_dropshipping || 'Leveringsadres voor dropshipping') : (translations.delivery_address || 'Leveringsadres') }}</h3>
                    </div>

                    <div v-if="!cart.dropshipping" class="uk-width-1-1">
                        <div class="uk-width-1-1 uk-child-width-1-3@s" uk-grid>
                            <div @click="shipping_address = address.id" v-for="address in shipping_addresses" class="mt-0 mb-5 delivery__address" :class="{'selected': shipping_address === address.id}">
                                <div class="delivery__address--text">
                                    <p class="my-0 uk-text-bold">{{ address.address_line_1 || '' }}</p>
                                    <p v-if="address.address_line_2" class="my-0">{{ address.address_line_2 || '' }}</p>
                                    <p v-if="address.address_line_3" class="my-0">{{ address.address_line_3 || '' }}</p>
                                    <p class="my-0">{{ address.postal_code }} {{ address.city }}, {{ address.country_name }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else class="uk-width-1-1 pt-3 uk-margin-remove-top">
                        <div uk-grid>
                            <div class="uk-width-1-2@s">
                                <label for="dropshipping_attn" class="mb-2 uk-display-inline-block form-custom__label">{{ translations.labels.attn }}</label>
                                <input class="uk-input" v-model="dropshipping_attn" type="text" :placeholder="translations.attn" name="dropshipping_attn" id="dropshipping_attn">
                                <p v-if="errors.dropshipping_attn" class="text--small uk-form-danger mt-2"><strong>{{ errors.dropshipping_attn[0] }}</strong></p>
                            </div>
                            <div class="uk-width-1-2@s">
                                <label for="dropshipping_address_line_1" class="mb-2 uk-display-inline-block form-custom__label">{{ translations.labels.address }}</label>
                                <input class="uk-input" v-model="dropshipping_address_line_1" type="text" :placeholder="translations.address_line_1" name="dropshipping_address_line_1" id="dropshipping_address_line_1">
                                <p v-if="errors.dropshipping_address_line_1" class="text--small uk-form-danger mt-2"><strong>{{ errors.dropshipping_address_line_1[0] }}</strong></p>
                            </div>
                            <div class="uk-width-1-2@s">
                                <label for="dropshipping_address_line_1" class="mb-2 uk-display-inline-block form-custom__label">{{ translations.labels.postal_code }}</label>
                                <input class="uk-input" v-model="dropshipping_postal_code" type="text" :placeholder="translations.address_line_1" name="dropshipping_postal_code" id="dropshipping_postal_code">
                                <p v-if="errors.dropshipping_postal_code" class="text--small uk-form-danger mt-2"><strong>{{ errors.dropshipping_postal_code[0] }}</strong></p>
                            </div>
                            <div class="uk-width-1-2@s">
                                <label for="dropshipping_address_line_1" class="mb-2 uk-display-inline-block form-custom__label">{{ translations.labels.city }}</label>
                                <input class="uk-input" v-model="dropshipping_city" type="text" :placeholder="translations.address_line_1" name="dropshipping_city" id="dropshipping_city">
                                <p v-if="errors.dropshipping_city" class="text--small uk-form-danger mt-2"><strong>{{ errors.dropshipping_city[0] }}</strong></p>
                            </div>
                            <div class="uk-width-1-2@s">
                                <label for="dropshipping_country_id" class="mb-2 uk-display-inline-block form-custom__label">{{ translations.labels.country }}</label>
                                <select class="uk-select" v-model.number="dropshipping_country_id" name="dropshipping_country_id" id="dropshipping_country_id">
                                    <option disabled value="">{{ translations.labels.pickachoice }}</option>
                                    <option v-for="(shortcode, id) in countries" :value="id">{{ translations.countries[shortcode] }}</option>
                                </select>
                                <p v-if="errors.dropshipping_country_id" class="text--small uk-form-danger mt-2"><strong>{{ errors.dropshipping_country_id[0] }}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
                <p v-if="errors.shipping_address" class="uk-form-danger mt-0"><strong>{{ errors.shipping_address[0] }}</strong></p>
            </div>
        </div>
    </div>
</template>

<script>
    import filter from 'lodash/filter';
    import isEmpty from 'lodash/isEmpty'

    export default {
        name: "CartInformation",
        data() {
            return {
                shipping_method:'SHIPPING',
                shipping_address: this.getCurrentShippingAddress(),
                dropshipping_attn: this.cart.dropshipping_attn,
                dropshipping_address_line_1: this.cart.dropshipping_address_line_1,
                dropshipping_postal_code: this.cart.dropshipping_postal_code,
                dropshipping_city: this.cart.dropshipping_city,
                dropshipping_country_id: this.getDefaultDropshippingCountryId(),
            }
        },
        computed: {
            shipping_addresses() {
                return filter(this.addresses, {type: 'shipping'});
            }
        },
        methods: {
            getCurrentShippingAddress () {
                if (this?.old?.shipping_address) {
                    return parseInt(this.old.shipping_address);
                }

                if (this.cart.shipping_address_id !== null) {
                    return this.cart.shipping_address_id;
                }

                return filter(this.addresses, {type: 'shipping'})?.[0]?.id ?? null;
            },
            getDefaultDropshippingCountryId(){
                if (isEmpty(this.cart.dropshipping_country_id)) {
                    return '';
                } else {
                    return parseInt(this.cart.dropshipping_country_id);
                }
            }
        },
        props: {
            translations: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            cart: {
                type: Object,
                required: true,
            },
            old: {
                type: Object|Array,
                required: false,
                default: () => { return {} }
            },
            addresses: {
                type: Array,
                required: false,
                default: () => { return [] }
            },
            errors: {
                type: Object|Array,
                default: () => { return {}; }
            },
            countries: {
                type: Object,
                default: () => { return {}; }
            }
        }
    }
</script>

<style scoped>

</style>