<template>
    <section class="uk-section uk-section-small pb-0" v-if="hasMorePages" ref="scrollelem">
        <div class="pagination">
            <a v-if="onFirstPage" aria-disabled="true" :aria-label="translations.previous" class="pagination__button uk-disabled">
                <i class="fas fa-chevron-left mr-sm-2"></i>
                <span class="uk-visible@s">{{ translations.previous }}</span>
            </a>
            <a v-else :href="paginator.previous_page_url" v-on:click.prevent="changePage(paginator.current_page == 1 ? 1 : paginator.current_page - 1)" rel="prev" :aria-label="translations.previous" class="pagination__button">
                <i class="fas fa-chevron-left mr-sm-2"></i>
                <span class="uk-visible@s">{{ translations.previous }}</span>
            </a>

            <template v-for="element in elements">
                <a v-if="typeof element == 'string'" class="pagination__number pagination__number--disabled uk-disabled">{{ element }}</a>

                <template v-if="typeof element == 'object'" v-for="(url, page) in element">
                    <a v-if="page == paginator.current_page" v-on:click.prevent="changePage(page)" class="pagination__number pagination__number--active uk-disabled" aria-current="page">{{ page }}</a>
                    <a v-else v-on:click.prevent="changePage(page)" :href="url" class="pagination__number">{{ page }}</a>
                </template>
            </template>

            <a v-if="hasMorePages" v-on:click.prevent="changePage(paginator.current_page + 1)" :href="paginator.next_page_url" :aria-label="translations.next" class="pagination__button" rel="next">
                <span class="uk-visible@s">{{ translations.next }}</span>
                <i class="fas fa-chevron-right ml-sm-2"></i>
            </a>
            <a v-else aria-disabled="true" :aria-label="translations.next" class="pagination__button uk-disabled" rel="next">
                <span class="uk-visible@s">{{ translations.next }}</span>
                <i class="fas fa-chevron-right ml-sm-2"></i>
            </a>
        </div>
    </section>
</template>

<script>
    import { EventBus } from "../EventBus";
    import { changeUrl } from "../functions";

    export default {
        name: "Paginator",
        data() {
            return {
                paginator: this.initPaginator,
                elements: this.initElements
            }
        },
        mounted() {
            if (this.initState) {
                this.pushCurrentPage();
            }

            window.addEventListener('popstate', (event) => {
                if (event.state && event.state.type == ('pagination_' + this.identifier)) {
                    this.changePage(event.state.page);
                }
            });

            EventBus.$on(`PAGINATOR_${this.identifier}_CHANGED`, paginator => {
                this.paginator = paginator;
                if (paginator.elements) {
                    this.elements = paginator.elements;
                }

                this.pushCurrentPage();
            })
        },
        computed: {
            hasMorePages() {
                return this.paginator.last_page > 1;
            },
            onFirstPage() {
                return this.paginator.current_page <= 1;
            }
        },
        watch: {
            initPaginator(newVal, oldVal) {
                this.paginator = newVal;
                if (newVal.elements) {
                    this.elements = newVal.elements;
                }

                EventBus.$emit('CHANGE_ITEMS_' + this.identifier, newVal.data);
            },
            initElements(newVal, oldVal) {
                this.elements = newVal;
            }
        },
        methods: {
            changePage(page) {
                window.axios.get(this.changeUrl(this.paginator.first_page_url, 'page', page))
                    .then(res => {
                        this.paginator = res.data;
                        if (res.data.elements) {
                            this.elements = res.data.elements;
                        }

                        EventBus.$emit('CHANGE_ITEMS_' + this.identifier, res.data.data);
                        this.pushCurrentPage();

                        if( this.scrollToTop ) {
                            UIkit.scroll(this.$refs.scrollelem).scrollTo("#content");
                        }
                    });
            },
            pushCurrentPage() {
                window.history.pushState(
                    {page: this.paginator.current_page, type: ('pagination_' + this.identifier)},
                    document.title,
                    this.changeUrl(this.changeUrl(this.paginator.first_page_url, 'page', this.paginator.current_page), '_v', Date.now())
                );
            },
            changeUrl
        },
        props: {
            initPaginator: {
                type: Object|Array,
                default: () => { return {}; }
            },
            initElements: {
                type: Array|Object,
                default: () => { return {}; }
            },
            initState: {
                type: Boolean,
                default: true,
                required: false
            },
            identifier: {
                type: String,
                default: "LIST",
                required: false
            },
            translations: {
                type: Object,
                default: () => {},
                required: true
            },
            scrollToTop: {
                default: true,
                required: false,
                type: Boolean|Number
            }
        }
    }
</script>

<style scoped>

</style>