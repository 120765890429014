var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c("multi-language-field", {
        attrs: {
          label: "Titel",
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["title"])
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("text-field", {
                  attrs: {
                    "init-errors": slotProps.errors.title || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".title"),
                    value: slotProps.title
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("title", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm._v(" "),
      _c("multi-language-field", {
        attrs: {
          label: "Inhoud",
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["body"])
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", _vm.input)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("textarea-field", {
                  attrs: {
                    "init-errors": slotProps.errors.body || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".body"),
                    value: slotProps.body
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("body", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm._v(" "),
      _c("repeater-field", {
        attrs: {
          types: ["Foto", "Video"],
          "init-errors": _vm.errors.items,
          label: "Foto / Video's",
          max: 2,
          sort: false
        },
        scopedSlots: _vm._u([
          {
            key: "Foto",
            fn: function(slotProps) {
              return [
                _c("upload-field", {
                  attrs: {
                    multi: false,
                    collection: "image-" + slotProps.index,
                    label: "Foto",
                    name: "images[]",
                    "link-identifier":
                      _vm.block.id + "-image-" + slotProps.index,
                    routes: {
                      upload: _vm.routes.upload.replace("-id-", _vm.block.id),
                      fetch: _vm.routes.getUploads.replace(
                        "-id-",
                        _vm.block.id
                      ),
                      sort: _vm.routes.sortUploads.replace(
                        "-id-",
                        _vm.block.id
                      ),
                      delete: _vm.routes.deleteUpload.replace(
                        "-id-",
                        _vm.block.id
                      )
                    }
                  }
                })
              ]
            }
          },
          {
            key: "Video",
            fn: function(slotProps) {
              return [
                _c("multi-language-field", {
                  attrs: {
                    value: slotProps.input,
                    label: "Video url *",
                    locales: _vm.locales,
                    "init-errors": _vm.only(_vm.errors, ["url"])
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput(null, value)
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(slotProps) {
                          return [
                            _c("text-field", {
                              attrs: {
                                "init-errors": slotProps.errors.url || [],
                                name: _vm.dotToName(_vm.fieldPrefix + ".url"),
                                value: slotProps.url
                              },
                              on: {
                                input: function(value) {
                                  return slotProps.handleInput("url", value)
                                }
                              }
                            })
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input.items,
          callback: function($$v) {
            _vm.$set(_vm.input, "items", $$v)
          },
          expression: "input.items"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }