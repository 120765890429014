/* IMPORTS
-------------------------------------------------- */
import Cookies from 'js-cookie';
import debounce from 'debounce';
import { outerHeight } from "./functions";

UIkit.use(Icons);

/* VARIABLES & DOM ELEMENTS
-------------------------------------------------- */
let sideBarState = Cookies.get('side_bar_status');

const sideBarToggle = document.querySelector('button#sidebar-toggle');
const sideBar = document.querySelector('nav#sidebar');
const mainContent = document.querySelector('main#content');

const flexibleContainer = document.querySelector('.flexible-container');

const productsGrid = document.querySelector('.products-grid');
const productsColumn = document.querySelector('.products-column');

const scrollToTop = document.getElementById('scroll-top');

let docuHeight = document.body.clientHeight;
let windowHeight = window.innerHeight;

/* DOCUMENT READY ACTIONS
-------------------------------------------------- */

/**
 * Function which executes a given function on DOM loaded.
 * @param {func} fn Function to be executed on DOM ready
 */
function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(() => {
    window.onscroll = debounce(() => {
        if (window.scrollY > (window.innerHeight / 3)) {
            scrollToTop.classList.remove('hidden');
        } else {
            scrollToTop.classList.add('hidden');
        }
    }, 20);

    // if (sideBar && sideBarToggle && mainContent) {
    //     if (!sideBarState || sideBarState === undefined) {
    //         Cookies.set('side_bar_status', 'true');
    //     }
    //
    //     if (sideBarState === 'false') {
    //         Cookies.set('side_bar_status', 'false');
    //         sideBar.classList.add('hidden');
    //         sideBarToggle.classList.add('hidden');
    //         mainContent.classList.add('hidden');
    //
    //         flexibleContainer
    //             ? flexibleContainer.classList.add('uk-container-large')
    //             : null;
    //
    //         productsGrid ? productsGrid.classList.add('products-grid--large') : null;
    //
    //         productsColumn
    //             ? productsColumn.classList.add('products-column--large')
    //             : null;
    //     } else {
    //         sideBar.classList.remove('hidden');
    //         sideBarToggle.classList.remove('hidden');
    //         mainContent.classList.remove('hidden');
    //     }
    // }
});

/* NAV ACTIONS
-------------------------------------------------- */

sideBarToggle
    ? sideBarToggle.addEventListener('click', (e) => {
        e.preventDefault();

        sideBarState = Cookies.get('side_bar_status');

        if (sideBarState === 'false') {
            sideBar.classList.remove('hidden');
            sideBarToggle.classList.remove('hidden');
            mainContent.classList.remove('hidden');

            flexibleContainer
                ? flexibleContainer.classList.remove('uk-container-large')
                : null;

            productsGrid
                ? productsGrid.classList.remove('products-grid--large')
                : null;

            productsColumn
                ? productsColumn.classList.remove('products-column--large')
                : null;

            Cookies.set('side_bar_status', 'true');
        } else {
            sideBar.classList.add('hidden');
            sideBarToggle.classList.add('hidden');
            mainContent.classList.add('hidden');

            flexibleContainer
                ? flexibleContainer.classList.add('uk-container-large')
                : null;

            productsGrid
                ? productsGrid.classList.add('products-grid--large')
                : null;

            productsColumn
                ? productsColumn.classList.add('products-column--large')
                : null;

            Cookies.set('side_bar_status', 'false');
        }
    })
    : null;

/* GLOBAL ALERTS
-------------------------------------------------- */

if (document.querySelector('#global-alert')) {
    document
        .querySelector('#global-alert > button#global-alert-close')
        .addEventListener('click', (e) => {
            e.preventDefault();
            document.querySelector('#global-alert').classList.add('d-none');
        });
}