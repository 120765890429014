<template>
    <div class="d-flex flex-sm-row flex-column justify-content-between align-items-sm-center mb-sm-5 mb-4">
        <h2 class="my-sm-0 mt-0 mb-3">Blokken</h2>

        <button class="uk-button uk-button-primary"><i class="fas fa-plus mr-2"></i> Toevoegen</button>
        <div uk-dropdown="mode: click; pos: bottom-right; animation: uk-animation-slide-top-small;" class="custom-dropdown">
            <ul class="uk-nav uk-dropdown-nav">
                <li v-for="block in blocks"><a @click="add(block)">{{ block.name }}</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "AddBlockComponent",
        methods: {
            add(block) {
                window.axios.post(this.route, {
                    id: block.id
                }).then(res => {
                    EventBus.$emit('PAGE_CONTENT_CHANGED', res.data.content);
                });
            }
        },
        props: {
            blocks: {
                type: Array,
                required: true
            },
            route: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>