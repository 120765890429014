<template>
    <div>
        <div class="uk-width-1-1">
            <div class="d-flex flex-sm-row flex-column justify-content-between align-items-sm-center mb-sm-4 mb-4">
                <h3 class="my-sm-0 mt-0 mb-3">{{ label }}</h3>
                <button v-if="types.length > 1" class="uk-button uk-button-small uk-button-primary" :disabled="(max !== null && fields.length >= max)" type="button"><i class="fas fa-plus mr-2"></i> Toevoegen</button>
                <button v-else @click.prevent="() => addBlock(types[0])" :disabled="(max !== null && fields.length >= max)" class="uk-button uk-button-primary uk-button-small"><i class="fas fa-plus mr-2"></i> Toevoegen</button>
                <div v-if="types.length > 1" uk-dropdown="mode: click; pos: bottom-right; animation: uk-animation-slide-top-small;" class="custom-dropdown">
                    <ul class="uk-nav uk-dropdown-nav">
                        <li v-for="type in types"><a href="#" @click="() => addBlock(type)">{{ type }}</a></li>
                    </ul>
                </div>
            </div>
            <hr>
        </div>

        <div class="uk-width-1-1 uk-padding-small" ref="sortable">
            <ul v-on:click.prevent.stop v-for="(field, index) in fields" class="page-accordion" :data-index="index" uk-accordion>
                <li>
                    <a class="uk-accordion-title uk-accordion-title--small">
                        <div class="d-flex align-items-center">
                            <div v-if="sort" class="repeater-handler mr-3"><img src="/assets/images/icons/actions/move.svg" draggable="false" uk-svg></div>
                            <p class="block-name my-0"><strong>{{ field._type }}</strong></p>
                        </div>

                        <div class="d-flex justify-content-end align-items-center">
                            <div class="indicator mr-3"><i class="fas fa-chevron-right"></i></div>

                            <button @click.prevent.stop="deleteBlock(index)"><img src="/assets/images/icons/actions/delete.svg" draggable="false" uk-svg></button>
                        </div>
                    </a>
                    <div class="uk-accordion-content">
                        <slot
                            :handle-input="(prop, value) => updateBlock(prop, value, index)"
                            :name="field._type"
                            :input="fields[index]"
                            :index="index"
                            :errors="errors[index]"
                        >
                        </slot>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RepeaterField",
        data() {
            return {
                fields: this.value,
                errors: this.initErrors
            }
        },
        mounted() {
            if (this.sort) {
                this.initSortable();
            }
        },
        watch: {
            initErrors (newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            label: {
                type: String,
                default: '',
                required: false
            },
            types: {
                type: Array,
                required: true,
                default: () => { return []; }
            },
            value: {
                type: Array,
                required: false,
                default: () => { return[]; }
            },
            initErrors: {
                type: Array,
                required: false,
                default: () => { return []; }
            },
            max: {
                type: Number,
                default: null,
                required: false
            },
            sort: {
                type: Boolean,
                default: true,
                required: false
            }
        },
        methods: {
            initSortable() {
                if (this.sortable) {
                    this.sortable = null;
                }

                this.sortable = UIkit.sortable(this.$refs.sortable, {
                    handle: '.repeater-handler',
                });

                UIkit.util.on(this.$refs.sortable, 'moved', (e) => this.handleSort(e));
            },
            addBlock(type) {
                this.fields.push({
                    _type: type
                });

                this.$emit('input', this.fields);
            },
            updateBlock(prop, value, index) {

                if (prop == null && typeof value == 'object') {
                    const fields = this.fields;
                    fields[index] = { ...fields[index], ...value };
                    this.fields = fields;
                } else {
                    this.fields[index][prop] = value;
                }

                this.$emit('input', this.fields);
            },
            deleteBlock(index) {
                this.fields.splice(index, 1);
            },
            handleSort(e) {
                e.stopImmediatePropagation();

                const indexes = _.map(e.target.children, (item) => {
                    return item.dataset.index;
                });

                const fields = _.sortBy(this.fields, (field) => {
                    const index = _.indexOf(this.fields, field);
                    return _.indexOf(indexes, index.toString());
                });

                fields.forEach((field, index) => {
                    this.$set(this.fields, index, field);
                });

                e.target.children.forEach((el, index) => {
                    el.setAttribute('data-index', index);
                });

                this.$emit('input', this.fields);
            }
        }
    }
</script>

<style scoped>

</style>