var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.label
      ? _c("div", { staticClass: "uk-width-1-1 uk-margin-small-bottom" }, [
          _c(
            "label",
            {
              staticClass: "mr-sm-4 mb-sm-0 mb-3 form-custom__label",
              attrs: { for: _vm.id || _vm._uid + "-" + _vm.name }
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.multi
      ? _c("div", [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.files && _vm.files.length > 0,
                  expression: "files && files.length > 0"
                }
              ]
            },
            [
              _c(
                "div",
                {
                  ref: "sortable_uploads",
                  staticClass:
                    "uk-child-width-1-4 uk-child-width-1-5@l uk-child-width-1-6@xl",
                  attrs: { "uk-grid": "" }
                },
                _vm._l(_vm.sortedFiles, function(file) {
                  return _c("div", { attrs: { "data-id": file.id } }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "uk-flex uk-flex-center uk-flex-middle uk-box-shadow-medium uk-padding-remove uk-card uk-card-default uk-card-body"
                      },
                      [
                        _c("div", { staticClass: "aspect-ratio" }, [
                          file.thumb != null
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-background-cover uk-width-1-1 uk-height-1-1 card-image",
                                  style: {
                                    backgroundImage: "url(" + file.thumb + ")"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "uk-link uk-text-small uk-position-absolute uk-position-top-right uk-link uk-margin-small-right",
                                      on: {
                                        click: function($event) {
                                          _vm.confirm(
                                            "Ben je zeker dat je dit bestand wil verwijderen?",
                                            function() {
                                              return _vm.deleteUpload(file.id)
                                            }
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/assets/images/icons/actions/delete.svg",
                                          draggable: "false",
                                          "uk-svg": ""
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-text-large uk-overflow-hidden uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-width-1-1 uk-height-1-1 card-image"
                                },
                                [
                                  _vm._m(0, true),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "uk-text-small uk-display-block"
                                    },
                                    [_vm._v(_vm._s(file.file_name))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "uk-link uk-position-absolute uk-text-small uk-position-top-right uk-link uk-margin-small-right",
                                      on: {
                                        click: function($event) {
                                          _vm.confirm(
                                            "Ben je zeker dat je dit bestand wil verwijderen?",
                                            function() {
                                              return _vm.deleteUpload(file.id)
                                            }
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            "/assets/images/icons/actions/delete.svg",
                                          draggable: "false",
                                          "uk-svg": ""
                                        }
                                      })
                                    ]
                                  )
                                ]
                              )
                        ])
                      ]
                    )
                  ])
                }),
                0
              )
            ]
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "uk-placeholder uk-text-center form-uploader mt-3",
                class: _vm.uploadClass
              },
              [
                _c("span", {
                  staticClass: "mr-2",
                  attrs: { "uk-icon": "icon: cloud-upload" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "uk-text-middle" }, [
                  _vm._v("Voeg bestanden toe door ze hier neer te zetten of")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    attrs: { "uk-form-custom": "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("input", {
                      attrs: { type: "file", multiple: _vm.multi }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "uk-link" }, [
                      _vm._v("door er een te selecteren")
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("progress", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isUploading,
                  expression: "isUploading"
                }
              ],
              ref: "progress",
              staticClass: "uk-progress uk-margin-remove-top",
              attrs: { max: _vm.barMax },
              domProps: { value: _vm.barValue }
            })
          ])
        ])
      : _c("div", [
          _c("div", { staticClass: "uk-width-1-1 uk-flex-wrap uk-flex" }, [
            _c(
              "div",
              {
                staticClass:
                  "uk-width-1-1 uk-width-1-2@s uk-flex-first@s uk-flex-last uk-flex uk-flex-wrap uk-flex-bottom",
                staticStyle: { "align-content": "flex-end" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "uk-width-1-1 uk-width-auto@s uk-button uk-button-secondary uk-button-small uk-margin-small-top",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.confirm(
                          "Ben je zeker dat je deze foto wil verwijderen?",
                          function() {
                            return _vm.deleteUpload(_vm.files[0].id)
                          }
                        )
                      }
                    }
                  },
                  [_vm._v("Verwijder")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "uk-width-1-1",
                    class: "js-upload-" + this._uid,
                    attrs: { "uk-form-custom": "" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                      }
                    }
                  },
                  [
                    _c("input", { attrs: { type: "file" } }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "uk-width-1-1 uk-width-auto@s uk-button uk-button-primary uk-button-small uk-margin-small-top"
                      },
                      [_vm._v("Selecteer")]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "uk-width-1-1 uk-width-1-2@s uk-flex-last@s uk-flex-first single-file-detail uk-position-relative uk-box-shadow-medium"
              },
              [
                _vm.files && _vm.files[0] && _vm.files[0].thumb != null
                  ? _c("div", {
                      staticClass:
                        "uk-background-cover uk-width-1-1 uk-height-1-1 card-image",
                      attrs: { "data-src": _vm.files[0].thumb, "uk-img": "" }
                    })
                  : _vm.files && _vm.files[0]
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "uk-text-large uk-overflow-hidden uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-width-1-1 uk-height-1-1 uk-background-default card-image"
                      },
                      [
                        _vm._m(1),
                        _vm._v(" "),
                        _c("span", { staticClass: "uk-display-block" }, [
                          _vm._v(_vm._s(_vm.files[0].file_name))
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-width-1-1 uk-margin-small-top" }, [
            _c("progress", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isUploading,
                  expression: "isUploading"
                }
              ],
              ref: "progress",
              staticClass: "uk-progress",
              attrs: { max: _vm.barMax },
              domProps: { value: _vm.barValue }
            })
          ])
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fa fa-file" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [_c("i", { staticClass: "fa fa-file" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }