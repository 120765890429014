var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stock > _vm.threshold
    ? _c(
        "div",
        {
          class: {
            "product__stock product__stock--success": _vm.mode === "default",
            "status status--success": _vm.mode === "compact"
          },
          attrs: { "uk-tooltip": !_vm.withText ? _vm.note : "" }
        },
        [
          _c("i", {
            staticClass: "fas fa-check-circle",
            class: { "mr-2": _vm.mode === "default" }
          }),
          _vm._v(" "),
          _vm.withText
            ? _c("p", { staticClass: "my-0" }, [_vm._v(_vm._s(_vm.note))])
            : _vm._e()
        ]
      )
    : _vm.stock > 0 ||
      ((!_vm.empty(_vm.dayRange) || !_vm.empty(_vm.date)) && _vm.stock >= 0)
    ? _c(
        "div",
        {
          class: {
            "product__stock product__stock--warning": _vm.mode === "default",
            "status status--warning": _vm.mode === "compact"
          },
          attrs: { "uk-tooltip": !_vm.withText ? _vm.note : "" }
        },
        [
          _c("i", {
            staticClass: "fas fa-check-circle",
            class: { "mr-2": _vm.mode === "default" }
          }),
          _vm._v(" "),
          _vm.withText
            ? _c("p", { staticClass: "my-0" }, [_vm._v(_vm._s(_vm.note))])
            : _vm._e()
        ]
      )
    : _c(
        "div",
        {
          class: {
            "product__stock product__stock--danger": _vm.mode === "default",
            "status status--danger": _vm.mode === "compact"
          },
          attrs: {
            "uk-tooltip": !_vm.withText
              ? _vm.note
              : _vm.translations.contact_us_for_delivery_date
          }
        },
        [
          _c("i", { staticClass: "fas fa-times-circle mr-2" }),
          _vm._v(" "),
          _vm.withText
            ? _c("p", { staticClass: "my-0" }, [_vm._v(_vm._s(_vm.note))])
            : _vm._e()
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }