var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "left" } }, [
    _c("div", { attrs: { id: "top-bar-search" } }, [
      _c("input", {
        directives: [
          { name: "model", rawName: "v-model", value: _vm.q, expression: "q" }
        ],
        attrs: {
          type: "search",
          name: "main-search",
          id: "main-search",
          placeholder: _vm._f("capitalize")(_vm.translations.placeholder)
        },
        domProps: { value: _vm.q },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.q = $event.target.value
            },
            _vm.searchTypeHandler
          ]
        }
      })
    ]),
    _vm._v(" "),
    (_vm.products.length > 0 || _vm.categories.length > 0) && _vm.q.length > 0
      ? _c(
          "div",
          { staticClass: "top-bar-search-results" },
          [
            _vm._l(_vm.categories, function(category) {
              return _c(
                "a",
                { staticClass: "category", attrs: { href: category.url } },
                [
                  _vm._v(
                    "\n            " + _vm._s(category.name) + "\n            "
                  ),
                  _c("i", { staticClass: "fas fa-arrow-right" })
                ]
              )
            }),
            _vm._v(" "),
            _vm._l(_vm.products, function(product) {
              return _c(
                "a",
                { staticClass: "product", attrs: { href: product.url } },
                [
                  _c("span", { staticClass: "image" }, [
                    _c("img", {
                      attrs: {
                        "data-src": product.img,
                        alt: product.name,
                        "uk-img": ""
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "content" }, [
                    _c("span", { staticClass: "sku" }, [
                      _vm._v(_vm._s(product.sku))
                    ]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(product.name))])
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("hr", { staticClass: "uk-margin-remove" }),
            _vm._v(" "),
            _c("div", { staticClass: "p-4 uk-width-1-1" }, [
              _c(
                "a",
                {
                  staticClass:
                    "uk-width-1-1 uk-button uk-button-primary uk-text-center",
                  attrs: { href: "/search?q=" + _vm.q }
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.translations.all_products || "Toon alle producten"
                    )
                  )
                ]
              )
            ])
          ],
          2
        )
      : _vm.q.length > 0
      ? _c("div", { staticClass: "top-bar-search-results uk-padding-small" }, [
          _c("strong", [_vm._v(_vm._s(_vm.translations.searchEmpty))])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }