var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.hasMorePages
    ? _c(
        "section",
        { ref: "scrollelem", staticClass: "uk-section uk-section-small pb-0" },
        [
          _c(
            "div",
            { staticClass: "pagination" },
            [
              _vm.onFirstPage
                ? _c(
                    "a",
                    {
                      staticClass: "pagination__button uk-disabled",
                      attrs: {
                        "aria-disabled": "true",
                        "aria-label": _vm.translations.previous
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-chevron-left mr-sm-2" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "uk-visible@s" }, [
                        _vm._v(_vm._s(_vm.translations.previous))
                      ])
                    ]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "pagination__button",
                      attrs: {
                        href: _vm.paginator.previous_page_url,
                        rel: "prev",
                        "aria-label": _vm.translations.previous
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.changePage(
                            _vm.paginator.current_page == 1
                              ? 1
                              : _vm.paginator.current_page - 1
                          )
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "fas fa-chevron-left mr-sm-2" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "uk-visible@s" }, [
                        _vm._v(_vm._s(_vm.translations.previous))
                      ])
                    ]
                  ),
              _vm._v(" "),
              _vm._l(_vm.elements, function(element) {
                return [
                  typeof element == "string"
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "pagination__number pagination__number--disabled uk-disabled"
                        },
                        [_vm._v(_vm._s(element))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(element, function(url, page) {
                    return typeof element == "object"
                      ? [
                          page == _vm.paginator.current_page
                            ? _c(
                                "a",
                                {
                                  staticClass:
                                    "pagination__number pagination__number--active uk-disabled",
                                  attrs: { "aria-current": "page" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.changePage(page)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(page))]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "pagination__number",
                                  attrs: { href: url },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.changePage(page)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(page))]
                              )
                        ]
                      : _vm._e()
                  })
                ]
              }),
              _vm._v(" "),
              _vm.hasMorePages
                ? _c(
                    "a",
                    {
                      staticClass: "pagination__button",
                      attrs: {
                        href: _vm.paginator.next_page_url,
                        "aria-label": _vm.translations.next,
                        rel: "next"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.changePage(_vm.paginator.current_page + 1)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "uk-visible@s" }, [
                        _vm._v(_vm._s(_vm.translations.next))
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "fas fa-chevron-right ml-sm-2" })
                    ]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "pagination__button uk-disabled",
                      attrs: {
                        "aria-disabled": "true",
                        "aria-label": _vm.translations.next,
                        rel: "next"
                      }
                    },
                    [
                      _c("span", { staticClass: "uk-visible@s" }, [
                        _vm._v(_vm._s(_vm.translations.next))
                      ]),
                      _vm._v(" "),
                      _c("i", { staticClass: "fas fa-chevron-right ml-sm-2" })
                    ]
                  )
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }