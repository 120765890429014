<template>
    <div class="products-row-item row align-items-center">
        <div v-if="item.purchasable.discount_percentage > 0" class="promo-banner uk-visible@m ml-5 uk-position-z-index">- {{ item.purchasable.discount_percentage }}%</div>

        <div class="col-1 mb-md-0 mb-4">
            <button @click.prevent="deleteItem" class="button__delete"><i class="fas fa-times"></i></button>
        </div>

        <div class="col-sm-2 col-3 mb-md-0 mb-4">
            <img :data-src="getImage(item)" alt="Placeholder product 1" draggable="false" width="100" uk-img>
        </div>

        <div class="col-md-5 col-sm-9 col-8 mb-md-0 mb-4">
            <div class="d-flex flex-column">
                <p class="mb-1 sku">{{ translations.product.artnr || ''}}: {{ item.purchasable.external_code }}</p>
                <h3 class="mt-0 mb-2">{{ item.purchasable.name }}</h3>
                <!--<p class="my-0 text&#45;&#45;small">Maat: <strong>X-Small</strong></p>-->
            </div>

            <p v-if="errors && errors.quantity" class="uk-text-danger">
                {{ errors.quantity[0] }}
            </p>
        </div>

        <div class="col-md-2 col-sm-5 col-8 offset-md-0 offset-sm-1 offset-4 mb-sm-0 mb-4">
            <div class="d-flex justify-content-md-end align-items-center">
                <input type="number" name="amount" v-model.number="quantity" :max="item.purchasable.available_stock" @change="updateItem" class="custom-number-input mr-3" min="1" value="1">

                <stock-indication
                    mode="compact"
                    :stock="item.purchasable.stock"
                    :translations="translations.stock || {}"
                    :threshold="stockThreshold || 5"
                    :date="item.purchasable.restock_date_formatted || null"
                    :with-text="false"
                ></stock-indication>
            </div>
        </div>

        <div class="col-md-2 col-sm-6 col-8 offset-4 offset-sm-0">
            <div class="d-flex justify-content-sm-end">
                <price class="my-0 mr-2 uk-h3" :price="item.total_formatted "></price>
            </div>
        </div>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "CartOverviewRowComponent",
        methods: {
            getImage(item) {
                const purchasable = item.purchasable;
                if (purchasable && purchasable.images && purchasable.images.length > 0) {
                    return item.purchasable.images[0].thumb;
                }
                return '';
            },
            updateItem() {
                const url = this.routes.update.replace('|id|', this.item.id);

                window.axios.put(url, {quantity: this.quantity})
                    .then(res => {
                        if (res.data && res.data.cart) {
                            EventBus.$emit('CART_UPDATED', res.data.cart);
                        }

                        if (res.data && res.data.message) {
                            EventBus.$emit('NOTIFY', {
                                message: res.data.message,
                                status: 'success'
                            });
                        }
                    })
                    .catch(err => {
                        EventBus.$emit('NOTIFY_ERROR');

                        if (err?.response?.data?.errors) {
                            this.errors = err?.response?.data?.errors;
                        }

                        this.quantity = this.item.quantity;
                    });
            },
            deleteItem() {
                const url = this.routes.delete.replace('|id|', this.item.id);

                window.axios.delete(url)
                    .then(res => {
                        if (res.data && res.data.cart) {
                            EventBus.$emit('CART_UPDATED', res.data.cart);
                        }

                        if (res.data && res.data.message) {
                            EventBus.$emit('NOTIFY', {
                                message: res.data.message,
                                status: 'success'
                            });
                        }
                    })
                    .catch(err => {
                        EventBus.$emit('NOTIFY_ERROR');
                    });
            }
        },
        data() {
            return {
                quantity: this.item.quantity,
                errors: null
            }
        },
        props: {
            item: {
                required: true,
                type: Object
            },
            translations: {
                type: Object,
                required: true
            },
            routes: {
                type: Object,
                required: true
            },
            stockThreshold: {
                type: Number,
                required: false,
                default: 5
            }
        }
    }
</script>

<style scoped>

</style>
