<template>
    <tr>
        <td>
            <div class="js-upload" uk-form-custom>
                <input type="file" ref="input">
                <button class="uk-button uk-button-default" type="button" tabindex="-1">Select</button>
                <div uk-spinner v-if="uploading"></div>
            </div>
        </td>
        <td>
            <p class="uk-text-danger" v-if="error">{{ error }}</p>
            <p v-else-if="file">{{ file.file_name }}</p>
        </td>
        <td>
            <div class="uk-flex" v-if="file">
                <a :href="file.url" download target="_blank" class="action-button action-button-primary">
                    <i class="fa fa-download"></i>
                </a>
                <a v-on:click.prevent="deleteFile(file)" class="action-button action-button-primary uk-margin-small-left">
                    <i class="fa fa-trash"></i>
                </a>
            </div>
        </td>
    </tr>

</template>

<script>
    export default {
        name: "Upload",
        mounted() {
            this.upload = UIkit.upload(this.$refs.input, {
                url: this.uploadRoute,
                multiple: false,
                params: {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    ...this.postParams
                },

                beforeAll: () => {
                   this.uploading = true;
                   this.error = null;
                },
                error: error => {
                    const response = JSON.parse(error.xhr.response);
                    this.error = response["errors"]["files.0"][0];
                    this.uploading = false;
                },
                beforeSend: function (environment) {
                    var {data, method, headers, xhr, responseType} = environment;
                    headers["X-Requested-With"] = "XMLHttpRequest";
                },
                completeAll: res => {
                    const data = JSON.parse(res.response);
                    this.file = data.media;
                    this.uploading = false;
                }

            });
        },
        data() {
            return {
                uploading: false,
                file: this.initFile,
                error: null
            }
        },
        props: {
            deleteRoute: {
                type: String,
                required: true,
            },
            uploadRoute: {
                type: String,
                required: true
            },
            initFile: {
                default: null
            },
            postParams: {
                default: () => { return {}; },
                type: Object
            }
        },
        methods: {
            deleteFile(file) {
                if (!confirm('Ben je zeker dat je dit bestand wil verwijderen?')) {
                    return;
                }

                const url = this.deleteRoute.replace("-id-", file.id);
                window.axios.delete(url)
                    .then(res => {
                        this.file = null;
                    });
            }
        }
    }
</script>

<style scoped>

</style>