var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "uk-width-1-1" }, [
    _c("div", { attrs: { "uk-grid": "" } }, [
      _vm.shipping_method === "SHIPPING"
        ? _c("div", { staticClass: "uk-width-1-1" }, [
            _c("div", { attrs: { "uk-grid": "" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.shipping_address,
                    expression: "shipping_address"
                  }
                ],
                attrs: { type: "hidden", name: "shipping_address" },
                domProps: { value: _vm.shipping_address },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.shipping_address = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "uk-width-1-1 mt-5" }, [
                _c("h3", { staticClass: "uk-text-primary" }, [
                  _vm._v(
                    _vm._s(
                      _vm.cart.dropshipping
                        ? _vm.translations.delivery_address_dropshipping ||
                            "Leveringsadres voor dropshipping"
                        : _vm.translations.delivery_address || "Leveringsadres"
                    )
                  )
                ])
              ]),
              _vm._v(" "),
              !_vm.cart.dropshipping
                ? _c("div", { staticClass: "uk-width-1-1" }, [
                    _c(
                      "div",
                      {
                        staticClass: "uk-width-1-1 uk-child-width-1-3@s",
                        attrs: { "uk-grid": "" }
                      },
                      _vm._l(_vm.shipping_addresses, function(address) {
                        return _c(
                          "div",
                          {
                            staticClass: "mt-0 mb-5 delivery__address",
                            class: {
                              selected: _vm.shipping_address === address.id
                            },
                            on: {
                              click: function($event) {
                                _vm.shipping_address = address.id
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "delivery__address--text" },
                              [
                                _c("p", { staticClass: "my-0 uk-text-bold" }, [
                                  _vm._v(_vm._s(address.address_line_1 || ""))
                                ]),
                                _vm._v(" "),
                                address.address_line_2
                                  ? _c("p", { staticClass: "my-0" }, [
                                      _vm._v(
                                        _vm._s(address.address_line_2 || "")
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                address.address_line_3
                                  ? _c("p", { staticClass: "my-0" }, [
                                      _vm._v(
                                        _vm._s(address.address_line_3 || "")
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("p", { staticClass: "my-0" }, [
                                  _vm._v(
                                    _vm._s(address.postal_code) +
                                      " " +
                                      _vm._s(address.city) +
                                      ", " +
                                      _vm._s(address.country_name)
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _c(
                    "div",
                    { staticClass: "uk-width-1-1 pt-3 uk-margin-remove-top" },
                    [
                      _c("div", { attrs: { "uk-grid": "" } }, [
                        _c("div", { staticClass: "uk-width-1-2@s" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mb-2 uk-display-inline-block form-custom__label",
                              attrs: { for: "dropshipping_attn" }
                            },
                            [_vm._v(_vm._s(_vm.translations.labels.attn))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.dropshipping_attn,
                                expression: "dropshipping_attn"
                              }
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              type: "text",
                              placeholder: _vm.translations.attn,
                              name: "dropshipping_attn",
                              id: "dropshipping_attn"
                            },
                            domProps: { value: _vm.dropshipping_attn },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.dropshipping_attn = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.dropshipping_attn
                            ? _c(
                                "p",
                                {
                                  staticClass: "text--small uk-form-danger mt-2"
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.errors.dropshipping_attn[0])
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-width-1-2@s" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mb-2 uk-display-inline-block form-custom__label",
                              attrs: { for: "dropshipping_address_line_1" }
                            },
                            [_vm._v(_vm._s(_vm.translations.labels.address))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.dropshipping_address_line_1,
                                expression: "dropshipping_address_line_1"
                              }
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              type: "text",
                              placeholder: _vm.translations.address_line_1,
                              name: "dropshipping_address_line_1",
                              id: "dropshipping_address_line_1"
                            },
                            domProps: {
                              value: _vm.dropshipping_address_line_1
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.dropshipping_address_line_1 =
                                  $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.dropshipping_address_line_1
                            ? _c(
                                "p",
                                {
                                  staticClass: "text--small uk-form-danger mt-2"
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors
                                          .dropshipping_address_line_1[0]
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-width-1-2@s" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mb-2 uk-display-inline-block form-custom__label",
                              attrs: { for: "dropshipping_address_line_1" }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.translations.labels.postal_code)
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.dropshipping_postal_code,
                                expression: "dropshipping_postal_code"
                              }
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              type: "text",
                              placeholder: _vm.translations.address_line_1,
                              name: "dropshipping_postal_code",
                              id: "dropshipping_postal_code"
                            },
                            domProps: { value: _vm.dropshipping_postal_code },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.dropshipping_postal_code =
                                  $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.dropshipping_postal_code
                            ? _c(
                                "p",
                                {
                                  staticClass: "text--small uk-form-danger mt-2"
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors.dropshipping_postal_code[0]
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-width-1-2@s" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mb-2 uk-display-inline-block form-custom__label",
                              attrs: { for: "dropshipping_address_line_1" }
                            },
                            [_vm._v(_vm._s(_vm.translations.labels.city))]
                          ),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.dropshipping_city,
                                expression: "dropshipping_city"
                              }
                            ],
                            staticClass: "uk-input",
                            attrs: {
                              type: "text",
                              placeholder: _vm.translations.address_line_1,
                              name: "dropshipping_city",
                              id: "dropshipping_city"
                            },
                            domProps: { value: _vm.dropshipping_city },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.dropshipping_city = $event.target.value
                              }
                            }
                          }),
                          _vm._v(" "),
                          _vm.errors.dropshipping_city
                            ? _c(
                                "p",
                                {
                                  staticClass: "text--small uk-form-danger mt-2"
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.errors.dropshipping_city[0])
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-width-1-2@s" }, [
                          _c(
                            "label",
                            {
                              staticClass:
                                "mb-2 uk-display-inline-block form-custom__label",
                              attrs: { for: "dropshipping_country_id" }
                            },
                            [_vm._v(_vm._s(_vm.translations.labels.country))]
                          ),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.dropshipping_country_id,
                                  expression: "dropshipping_country_id",
                                  modifiers: { number: true }
                                }
                              ],
                              staticClass: "uk-select",
                              attrs: {
                                name: "dropshipping_country_id",
                                id: "dropshipping_country_id"
                              },
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.dropshipping_country_id = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { disabled: "", value: "" } },
                                [
                                  _vm._v(
                                    _vm._s(_vm.translations.labels.pickachoice)
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.countries, function(shortcode, id) {
                                return _c(
                                  "option",
                                  { domProps: { value: id } },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.translations.countries[shortcode]
                                      )
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.errors.dropshipping_country_id
                            ? _c(
                                "p",
                                {
                                  staticClass: "text--small uk-form-danger mt-2"
                                },
                                [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.errors.dropshipping_country_id[0]
                                      )
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    ]
                  )
            ]),
            _vm._v(" "),
            _vm.errors.shipping_address
              ? _c("p", { staticClass: "uk-form-danger mt-0" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.errors.shipping_address[0]))])
                ])
              : _vm._e()
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }