<template>
    <div class="nav-overlay uk-navbar-left uk-flex-1 uk-position-relative" hidden>
        <div class="uk-navbar-item uk-width-expand">
            <form class="uk-search uk-search-navbar uk-width-1-1">
                <input
                    v-model="q"
                    @input="searchTypeHandler"
                    class="uk-search-input"
                    type="search"
                    :placeholder="translations.placeholder | capitalize"
                    autofocus
                >
            </form>
        </div>

        <a class="uk-navbar-toggle" uk-close uk-toggle="target: .nav-overlay; animation: uk-animation-fade" href="#"></a>

        <div class="search-dropdown" v-show="q.length > 0" ref="drop">
            <div v-if="products.length > 0 || categories.length > 0 ">
                <a v-for="category in categories" :href="category.url" class="category">
                    {{ category.name }}
                    <i class="fas fa-arrow-right"></i>
                </a>

                <a v-for="product in products" :href="product.url" class="product">
            <span class="image">
                <img :data-src="product.img" :alt="product.name" uk-img/>
            </span>

                    <span class="content">
                <span class="sku">{{ product.sku }}</span>
                <p>{{ product.name }}</p>
            </span>
                </a>

                <hr class="uk-margin-remove">
                <div class="p-4 uk-width-1-1">
                    <a :href="`/search?q=${q}`" class="uk-width-1-1 uk-button uk-button-primary uk-text-center">{{ translations.all_products || 'Toon alle producten' }}</a>
                </div>
            </div>

            <div v-else>
                <strong>{{ translations.searchEmpty }}</strong>
            </div>
        </div>
    </div>
</template>

<script>
    import debounce from 'debounce';
    import { EventBus } from "../EventBus";

    export default {
        name: "SearchBarMobile",
        data() {
            return {
                q: '',
                results: {},
            }
        },
        created() {
            this.searchTypeHandler = debounce(this.searchTypeHandler, 300);
        },
        mounted() {
            this.drop = UIkit.dropdown(this.$refs.drop, {
                boundary: '.nav-overlay',
                boundaryAlign: true
            });
        },
        computed: {
            products() {
                return this?.results?.products ?? [];
            },
            categories() {
                return this?.results?.categories ?? [];
            }
        },
        methods: {
            debounce,
            searchTypeHandler() {
                axios.get(`${this.route}?q=${this.q}`)
                    .then(res => {
                        if (res?.data) {
                            this.results = res.data;
                        }

                        if ((this.products.length > 0 || this.categories.length > 0) && this.q.length > 0) {
                            this.drop.show();
                        } else {
                            this.drop.hide();
                        }
                    });
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        props: ['route', 'translations']
    }
</script>

<style scoped>

</style>