<template>
    <form>
        <multi-language-field
            :locales="locales"
            v-model="input"
            :init-errors="only(errors, ['title'])"
            label="Titel"
        >
            <template v-slot:default="slotProps">
                <text-field
                    :init-errors="slotProps.errors.title || []"
                    :name="dotToName(`${fieldPrefix}.title`)"
                    :value="slotProps.title"
                    @input="value => slotProps.handleInput('title', value)"
                ></text-field>
            </template>
        </multi-language-field>

        <repeater-field
            :types="['Question']"
            v-model="input.questions"
            :init-errors="errors.questions"
            label="Vragen"
        >
            <template v-slot:Question="slotProps">
                <multi-language-field
                    :value="slotProps.input"
                    :locales="locales"
                    label="Vraag"
                    :init-errors="slotProps.errors || {}"
                    @input="value => slotProps.handleInput(null, value)"
                >
                    <template v-slot:default="slotProps">
                        <text-field
                            class="uk-margin-medium-bottom"
                            :init-errors="slotProps.errors.title || []"
                            :name="dotToName(`${fieldPrefix}.title`)"
                            :value="slotProps.title"
                            @input="value => slotProps.handleInput('title', value)"
                        ></text-field>
                    </template>
                </multi-language-field>

                <multi-language-field
                    :value="slotProps.input"
                    :locales="locales"
                    label="Antwoord"
                    :init-errors="slotProps.errors || {}"
                    @input="value => slotProps.handleInput(null, value)"
                >
                    <template v-slot:default="slotProps">
                        <textarea-field
                            class="uk-margin-medium-bottom"
                            :init-errors="slotProps.errors.body || []"
                            :name="dotToName(`${fieldPrefix}.body`)"
                            :value="slotProps.body"
                            @input="value => slotProps.handleInput('body', value)"
                        ></textarea-field>
                    </template>
                </multi-language-field>
            </template>
        </repeater-field>
    </form>
</template>

<script>
    import TextField from "../Fields/TextField";
    import { dotToName } from "../../functions";
    import RepeaterField from "../Fields/RepeaterField";
    import TextareaField from "../Fields/TextareaField";

    export default {
        name: "FaqBlock",
        components: {TextareaField, RepeaterField, TextField},
        data() {
            return {
                block: this.initBlock,
                input: this.initInput,
                errors: this.initErrors,
            }
        },
        methods: {
            dotToName: dotToName,
            only(object, keys = []) {
                return _.mapValues(object, lang => {
                    return _.pick(lang, keys);
                });
            },
        },
        watch: {
            initErrors(newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            initBlock: {
                type: Object,
                required: true
            },
            fieldPrefix: {
                type: String,
                required: false,
                default: ''
            },
            initInput: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            initErrors: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            locales: {
                type: Object,
                required: false,
                default: () => { return []; }
            },
            routes: {
                type: Object,
                required: false,
                default: () => { return {} }
            }
        }
    }
</script>