<template>
    <div>
        <div class="uk-width-1-1 uk-margin-small-bottom" v-if="label">
            <label :for="id || `${_uid}-${name}`" class="mr-sm-4 mb-sm-0 mb-3 form-custom__label">{{ label }}</label>
        </div>

        <div v-if="multi">
            <div v-show="files && files.length > 0">
                <div ref="sortable_uploads" class="uk-child-width-1-4 uk-child-width-1-5@l uk-child-width-1-6@xl" uk-grid>
                    <div v-for="file in sortedFiles" :data-id="file.id">
                        <div class="uk-flex uk-flex-center uk-flex-middle uk-box-shadow-medium uk-padding-remove uk-card uk-card-default uk-card-body">
                            <div class="aspect-ratio">
                                <div v-if="file.thumb != null" class="uk-background-cover uk-width-1-1 uk-height-1-1 card-image" :style="{ backgroundImage: 'url(' + file.thumb + ')' }">
                                    <span class="uk-link uk-text-small uk-position-absolute uk-position-top-right uk-link uk-margin-small-right" @click="confirm('Ben je zeker dat je dit bestand wil verwijderen?', () => deleteUpload(file.id))">
                                        <img src="/assets/images/icons/actions/delete.svg" draggable="false" uk-svg>
                                    </span>
                                </div>
                                <div v-else class="uk-text-large uk-overflow-hidden uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-width-1-1 uk-height-1-1 card-image">
                                    <span><i class="fa fa-file"></i></span>
                                    <span class="uk-text-small uk-display-block">{{ file.file_name }}</span>
                                    <span class="uk-link uk-position-absolute uk-text-small uk-position-top-right uk-link uk-margin-small-right" @click="confirm('Ben je zeker dat je dit bestand wil verwijderen?', () => deleteUpload(file.id))">
                                        <img src="/assets/images/icons/actions/delete.svg" draggable="false" uk-svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="uk-placeholder uk-text-center form-uploader mt-3" :class="uploadClass">
                    <span uk-icon="icon: cloud-upload" class="mr-2"></span>
                    <span class="uk-text-middle">Voeg bestanden toe door ze hier neer te zetten of</span>
                    <div @click.stop uk-form-custom>
                        <input type="file" :multiple="multi">
                        <span class="uk-link">door er een te selecteren</span>
                    </div>
                </div>
                <progress class="uk-progress uk-margin-remove-top" ref="progress" :value="barValue" :max="barMax" v-show="isUploading"></progress>
            </div>
        </div>

        <div v-else>
            <div class="uk-width-1-1 uk-flex-wrap uk-flex">
                <div class="uk-width-1-1 uk-width-1-2@s uk-flex-first@s uk-flex-last uk-flex uk-flex-wrap uk-flex-bottom" style="align-content: flex-end">
                    <button @click.prevent="confirm('Ben je zeker dat je deze foto wil verwijderen?', () => deleteUpload(files[0].id))" class="uk-width-1-1 uk-width-auto@s uk-button uk-button-secondary uk-button-small uk-margin-small-top" type="button">Verwijder</button>
                    <div @click.stop class="uk-width-1-1" :class="`js-upload-${this._uid}`" uk-form-custom>
                        <input type="file">
                        <button class="uk-width-1-1 uk-width-auto@s uk-button uk-button-primary uk-button-small uk-margin-small-top">Selecteer</button>
                    </div>
                </div>
                <div class="uk-width-1-1 uk-width-1-2@s uk-flex-last@s uk-flex-first single-file-detail uk-position-relative uk-box-shadow-medium">
                    <div v-if="files && files[0] && files[0].thumb != null" class="uk-background-cover uk-width-1-1 uk-height-1-1 card-image" :data-src="files[0].thumb" uk-img></div>
                    <div  v-else-if="files && files[0]" class="uk-text-large uk-overflow-hidden uk-flex uk-flex-column uk-flex-center uk-flex-middle uk-width-1-1 uk-height-1-1 uk-background-default card-image">
                        <span><i class="fa fa-file"></i></span>
                        <span class="uk-display-block">{{ files[0].file_name }}</span>
                    </div>
                </div>
            </div>
            <div class="uk-width-1-1 uk-margin-small-top">
                <progress class="uk-progress" ref="progress" :value="barValue" :max="barMax" v-show="isUploading"></progress>
            </div>
        </div>

    </div>
</template>

<script>
    import { EventBus } from "../../EventBus";
    import { confirm } from "../../functions";

    export default {
        name: "UploadField",
        data() {
            return {
                files: [],
                isUploading: false,
                barMax: 100,
                barValue: 0,
            }
        },
        computed: {
            uploadClass() {
                return {
                    'uk-margin-remove-bottom': this.isUploading,
                    [`js-upload-${this._uid}`]: true
                }
            },
            sortedFiles() {
                return _.sortBy(this.files, 'order_column')
            }
        },
        mounted() {
            this.files = this.fetchFiles();
            this.bar = this.$refs.progress;
            UIkit.upload(`.js-upload-${this._uid}`, {
                url: this.routes.upload,
                params: {_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'), collection: this.collection, name: this.name.replace(/\W/g, '')},
                multiple: this.multi,
                name: this.name,
                beforeSend: function (e) {

                },
                beforeAll: function () {
                    // console.log('beforeAll', arguments);
                },
                load: function () {
                    // console.log('load', arguments);
                },
                error: function () {
                    // console.log('error', arguments);
                },
                complete: function (e) {
                    const res = JSON.parse(e.response);
                    EventBus.$emit(`${this.linkIdentifier ?? this._uid}_FILES_CHANGED`, res.media);
                }.bind(this),
                loadStart: function (e) {
                    this.isUploading = true;
                    this.barMax = e.total;
                    this.barValue = e.loaded;
                }.bind(this),
                progress: function (e) {
                    // console.log('progress', arguments);

                    this.barMax = e.total;
                    this.barValue = e.loaded;
                }.bind(this),
                loadEnd: function (e) {
                    // console.log('loadEnd', arguments);

                    this.barMax = e.total;
                    this.barValue = e.loaded;
                }.bind(this),
                completeAll: function() {
                    // console.log('completeAll', arguments);

                    setTimeout(function () {
                        this.isUploading = false;
                    }.bind(this), 1000);
                }.bind(this)
            });

            this.sortable = UIkit.sortable(this.$refs.sortable_uploads);

            UIkit.util.on(this.$refs.sortable_uploads, 'moved', (e) => this.handleSort(e));
            EventBus.$on(`${this.linkIdentifier ?? this._uid}_FILES_CHANGED`, (files, ignore) => {
                if (ignore != this._uid) {
                    this.files = files;
                }
            })
        },
        methods: {
            confirm,
            fetchFiles() {
                axios.get(this.routes.fetch.replace('-collection-', this.collection))
                    .then(res => {
                        if (res.data) {
                            this.files = res.data;
                        }
                    });
            },
            handleSort(e) {
                const ids = _.map(e.target.children, (item) => {
                    return item.dataset.id;
                });

                this.files = _.sortBy(this.files, (item) => {
                    return _.indexOf(ids, item.id.toString());
                });

                this.postSort();
            },
            postSort() {
                window.axios.post(this.routes.sort, {
                    files: _.map(this.files, 'id'),
                    collection: this.collection
                })
                    .then(res => {
                        EventBus.$emit(`${this.linkIdentifier ?? this._uid}_FILES_CHANGED`, res.data, this._uid);
                    });
            },
            deleteUpload(id) {
                window.axios.post(this.routes.delete.replace('-upload-id-', id), {_method: 'DELETE', collection: this.collection})
                    .then(res => {
                        EventBus.$emit(`${this.linkIdentifier ?? this._uid}_FILES_CHANGED`, res.data);
                    })
                    .catch(err => {
                        console.error(err);
                    });
            }
        },
        props: {
            name: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: false,
            },
            label: {
                type: String,
                required: true,
            },
            multi: {
                type: Boolean,
                required: false,
                default: false
            },
            collection: {
                type: String,
                required: true,
            },
            routes: {
                type: Object,
                required: true
            },
            linkIdentifier: {
                type: String,
                required: false,
                default: null
            }
        }
    }
</script>

<style scoped>
    .card-image {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 4px;
    }

    .single-file-detail {
        height: 200px;
    }

    .aspect-ratio {
        position: relative;
        width: 100%;
        border-radius: 4px;
        height: 0;
        padding-top: 100%;
    }
</style>