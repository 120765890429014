import { render, staticRenderFns } from "./FavoriteItemRow.vue?vue&type=template&id=73875f70&scoped=true&"
import script from "./FavoriteItemRow.vue?vue&type=script&lang=js&"
export * from "./FavoriteItemRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73875f70",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ploi/b2b.wildandsoft.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('73875f70')) {
      api.createRecord('73875f70', component.options)
    } else {
      api.reload('73875f70', component.options)
    }
    module.hot.accept("./FavoriteItemRow.vue?vue&type=template&id=73875f70&scoped=true&", function () {
      api.rerender('73875f70', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/FavoriteItemRow.vue"
export default component.exports