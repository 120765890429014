var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c("multi-language-field", {
        attrs: {
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["title"]),
          label: "Titel"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("text-field", {
                  attrs: {
                    "init-errors": slotProps.errors.title || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".title"),
                    value: slotProps.title
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("title", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm._v(" "),
      _c("multi-language-field", {
        attrs: {
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["body"]),
          label: "Intro"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("textarea-field", {
                  attrs: {
                    "init-errors": slotProps.errors.body || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".body"),
                    value: slotProps.body
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("body", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm._v(" "),
      _c("multi-language-field", {
        attrs: {
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["table"]),
          label: "Tabel"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("ckeditor-field", {
                  attrs: {
                    "custom-toolbar": [["Table"]],
                    "init-errors": slotProps.errors.table || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".table"),
                    value: slotProps.table
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("table", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }