<template>
    <form>
        <multi-language-field
            label="Titel"
            :locales="locales"
            v-model="input"
            :init-errors="only(errors, ['title'])"
        >
            <template v-slot:default="slotProps">
                <text-field
                    :init-errors="slotProps.errors.title || []"
                    :name="dotToName(`${fieldPrefix}.title`)"
                    :value="slotProps.title"
                    @input="value => slotProps.handleInput('title', value)"
                ></text-field>
            </template>
        </multi-language-field>

        <multi-language-field
            label="Inhoud"
            :locales="locales"
            v-model="input"
            @input="$emit('input', input)"
            :init-errors="only(errors, ['body'])"
        >
            <template v-slot:default="slotProps">
                <textarea-field
                    :init-errors="slotProps.errors.body || []"
                    :name="dotToName(`${fieldPrefix}.body`)"
                    :value="slotProps.body"
                    @input="value => slotProps.handleInput('body', value)"
                ></textarea-field>
            </template>
        </multi-language-field>

        <repeater-field
            :types="['Foto', 'Video']"
            v-model="input.items"
            :init-errors="errors.items"
            label="Foto / Video's"
            :max="2"
            :sort="false"
        >
            <template v-slot:Foto="slotProps">
                <upload-field
                    :multi="false"
                    :collection="`image-${slotProps.index}`"
                    label="Foto"
                    name="images[]"
                    :link-identifier="`${block.id}-image-${slotProps.index}`"
                    :routes="{
                    upload: routes.upload.replace('-id-', block.id),
                    fetch: routes.getUploads.replace('-id-', block.id),
                    sort: routes.sortUploads.replace('-id-', block.id),
                    delete: routes.deleteUpload.replace('-id-', block.id),
                }"
                ></upload-field>
            </template>

            <template v-slot:Video="slotProps">
                <multi-language-field
                    :value="slotProps.input"
                    label="Video url *"
                    :locales="locales"
                    @input="value => slotProps.handleInput(null, value)"
                    :init-errors="only(errors, ['url'])"
                >
                    <template v-slot:default="slotProps">
                        <text-field
                            :init-errors="slotProps.errors.url || []"
                            :name="dotToName(`${fieldPrefix}.url`)"
                            :value="slotProps.url"
                            @input="value => slotProps.handleInput('url', value)"
                        ></text-field>
                    </template>
                </multi-language-field>
            </template>
        </repeater-field>
    </form>
</template>

<script>
    import TextField from "../Fields/TextField";
    import { dotToName } from "../../functions";

    export default {
        name: "PhotoVideoBlock",
        components: {TextField},
        data() {
            return {
                block: this.initBlock,
                input: this.initInput,
                errors: this.initErrors,
            }
        },
        methods: {
            dotToName: dotToName,
            only(object, keys = []) {
                return _.mapValues(object, lang => {
                    return _.pick(lang, keys);
                });
            },
        },
        watch: {
            initErrors(newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            initBlock: {
                type: Object,
                required: true
            },
            fieldPrefix: {
                type: String,
                required: false,
                default: ''
            },
            initInput: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            initErrors: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            locales: {
                type: Object,
                required: false,
                default: () => { return []; }
            },
            routes: {
                type: Object,
                required: false,
                default: () => { return {} }
            }
        }
    }
</script>