<template>
    <div class="products-row-item row align-items-center">
        <div v-if="product.discount > 0" class="promo-banner ml-5 uk-visible@m uk-position-z-index">- {{ product.discount_percentage }}%</div>

        <div class="col-1 mb-md-0 mb-4">
            <button @click="deleteItem" class="button__delete"><i class="fas fa-times"></i></button>
        </div>

        <div class="col-md-1 col-sm-2 col-6 offset-md-1 offset-sm-0 offset-3 mb-md-0 mb-4">
            <a :href="detailRoute">
                <img :data-src="firstImage" draggable="false" uk-img>
            </a>
        </div>

        <div class="col-md-3 col-sm-5 mb-md-0 mb-4">
            <div class="d-flex flex-column">
                <a :href="detailRoute">
                    <p class="mb-1 products-row-item__sku">{{ translations.sku }}: {{ product.external_code }}</p>
                    <h3 class="mb-0 mt-0 products-row-item__name">{{ product.name }}</h3>
                </a>
            </div>
        </div>

        <div class="col-md-3 col-sm-5 mb-sm-0 mb-4">
            <div class="d-flex justify-content-md-end align-items-center">
                <!--<select name="sizing" class="custom-select mr-3">-->
                <!--<option value="xsmall">X-Small</option>-->
                <!--<option value="small">Small</option>-->
                <!--<option value="medium">Medium</option>-->
                <!--<option value="large">Large</option>-->
                <!--<option value="xlarge">X-Large</option>-->
                <!--<option value="xxlarge">XX-Large</option>-->
                <!--</select>-->

                <input v-model.number="quantity" type="number" name="amount" class="custom-number-input mr-3" min="1" value="1">

                <stock-indication
                    mode="compact"
                    :stock="product.stock"
                    :translations="translations.stock || {}"
                    :threshold="stockThreshold"
                    :date="product.restock_date_formatted || null"
                    :with-text="false"
                ></stock-indication>
            </div>
        </div>

        <div class="col-md-2 col-sm-5 col-9">
            <div class="d-flex flex-column align-items-sm-end align-items-md-start">
                <div class="d-flex align-items-baseline">
                    <price :price="product.price_formatted" class="text-small my-0"></price>
                    <p v-if="product.discount > 0" class="my-0 text--small">/ {{ product.unit || translations.unit }}</p>
                </div>

                <p class="my-0 text--small text--muted" v-if="product.discount > 0"><s>&euro; {{ format_price(product.original_price_formatted) }}</s></p>
            </div>
        </div>

        <div class="col-sm-1 col-3">
            <add-to-cart-button
                v-if="showProduct(product)"
                class="button__cart"
                :route="routes.addToCart"
                :purchasable-type="product.purchasable_type"
                :purchasable-id="product.id"
                :quantity="quantity"
            >
                <img src="/assets/images/icons/cart.svg" draggable="false" height="18" uk-svg>
            </add-to-cart-button>
        </div>
    </div>
</template>

<script>
    import { format_price } from '../functions.js';
    import StockIndication from "./StockIndication";
    import AddToCartButton from "./AddToCartButton";
    import { EventBus } from "../EventBus";
    import { confirm } from "../functions";

    export default {
        name: "FavoriteItemRow",
        components: {AddToCartButton, StockIndication},
        methods: {
            format_price,
            confirm,
            deleteItem() {
                axios.delete(this.routes.deleteFavorite.replace('-id-', this.product.id))
                    .then(res => {
                        if (res?.data?.message) {
                            EventBus.$emit('NOTIFY', res.data.message);
                        }

                        this.$emit('list-updated', res.data.favorites);
                    });
            },
            showProduct(product) {
                if (product.stock > 0) {
                    return true;
                }

                if (this.allowBackorder) {
                    return true;
                }

                return false;
            }
        },
        data() {
            return {
                quantity: 1
            }
        },
        computed: {
            detailRoute() {
                return this.routes.detail.replace('-id-', this.product.id)
            },
            firstImage() {
                return this.product?.images[0]?.thumb ?? this.product?.images[0]?.url ?? '';
            }
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            translations: {
                type: Object,
                required: true
            },
            border: {
                type: Boolean,
                required: false,
                default: true
            },
            stockThreshold: {
                type: Number,
                required: false,
                default: 5
            },
            imageDisplayMode: {
                type: String,
                required: false,
                default: 'contain'
            },
            routes: {
                type: Object,
                required: true
            },
            allowBackorder: {
                type: Boolean,
                required: false,
                default: true
            }
        }
    }
</script>

<style scoped>

</style>
