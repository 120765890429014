<template>
    <toggle-input
        :disabled="disabled"
        :value="val"
        @input="handle"
    ></toggle-input>
</template>

<script>
    export default {
        name: "AjaxToggle",
        data() {
            return {
                val: this.value
            }
        },
        methods: {
            handle() {
                window.axios.post(this.route);
            }
        },
        props: {
            value: {
                type: Number|Boolean,
                required: false,
                default: false
            },
            route: {
                type: String,
                required: true
            },
            disabled: {
                type: Number|Boolean,
                required: false,
                default: false
            }
        },
    }
</script>

<style scoped>

</style>