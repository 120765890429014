<template>
    <component :is="tag" v-if="content === null">
        <slot></slot>
    </component>
    <component :is="tag" v-else>
        {{ content }}
    </component>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "DynamicValue",
        data() {
            return {
                content: null
            }
        },
        mounted() {
            EventBus.$on(`UPDATE_${this.connector}`, content => {
                this.content = content;
            });
        },
        props: {
            connector: {
                type: String,
                required: true
            },
            tag: {
                type: String,
                required: false,
                default: 'span'
            }
        }
    }
</script>

<style scoped>

</style>