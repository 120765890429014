var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.cart.items.length < 1
        ? _c("div", [
            _c("p", [_vm._v(_vm._s(_vm.translations.cart_empty))]),
            _vm._v(" "),
            _c("a", { staticClass: "uk-link", attrs: { href: "/" } }, [
              _c("i", { staticClass: "fas fa-arrow-left mr-3" }),
              _vm._v(" " + _vm._s(_vm.translations.continue_shopping))
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.cart.items, function(item) {
        return _c("cart-overview-row-component", {
          key: item.id,
          attrs: {
            item: item,
            translations: _vm.translations,
            routes: _vm.routes,
            "stock-threshold": _vm.stockThreshold
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }