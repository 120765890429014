<template>
    <component is="button" @click.prevent="addToCart">
        <slot></slot>
    </component>
</template>

<script>
    import Axios from 'axios';
    import { EventBus } from "../EventBus";

    export default {
        name: "AddToCartButton",
        methods: {
            addToCart() {
                const data = {
                    quantity: this.quantity,
                    purchasable_id: this.purchasableId,
                    purchasable_type: this.purchasableType,
                };

                Axios.post(this.route, data)
                    .then(res => {
                        if (res.data && res.data.cart) {
                            EventBus.$emit('CART_UPDATED', res.data.cart);
                        }

                        if (res.data && res.data.message) {
                            EventBus.$emit('NOTIFY', {
                                message: res.data.message,
                                status: 'success'
                            });
                        }

                        this.$emit('add-success');
                    })
                    .catch(err => {
                        EventBus.$emit('NOTIFY_ERROR');
                    });
            }
        },
        props: {
            quantity: {
                type: Number,
                default: 1
            },
            purchasableId: {
                type: Number,
                required: true
            },
            purchasableType: {
                type: String,
                required: true
            },
            route: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>