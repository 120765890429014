<template>
    <div>
        <div v-if="label !== null && label !== ''">
            <label class="mr-sm-4 mb-3 form-custom__label">{{ label }}</label>
        </div>
        <ul class="tabs-nav uk-margin-small-top" :uk-switcher="`connect: .locale-switcher-${_uid}`">
            <li v-for="(locale, shortcode) in locales" class="uk-active">
                <a href="">{{ locale.name }} <span v-if="!empty(errors[shortcode])"><i class="fa fa-exclamation-circle"></i></span></a>
            </li>
        </ul>
        <ul class="uk-switcher mt-1 mb-4" :class="`locale-switcher-${_uid}`">
            <li v-for="(locale, shortcode) in locales">
                <slot :locale="shortcode" v-bind="input[shortcode]" :errors="errors[shortcode] || []" :handle-input="(prop, value) => handleInput(shortcode, prop, value)"></slot>
            </li>
        </ul>
    </div>
</template>

<script>
    import empty from 'lodash/isEmpty';

    export default {
        name: "MultiLanguageField",
        data() {
            return {
                input: this.initInput(),
                errors: this.initErrors
            }
        },
        watch: {
            initErrors (newVal, oldVal) {
                this.errors = newVal;
            }
        },
        methods: {
            empty,
            initInput() {
                let langs = this.value;
                Object.keys(this.locales).forEach(locale => {
                    if (!langs.hasOwnProperty(locale)) {
                        langs[locale] = {};
                    }

                    langs[locale] = {...langs[locale]}
                });

                return langs;
            },
            handleInput(locale, prop, value) {
                this.input[locale][prop] = value;
                this.$emit('input', this.input);
            }
        },
        props: {
            locales: {
                type: Object,
                required: true
            },
            value: {
                type: Object,
                required: true
            },
            label: {
                type: String,
                default: null,
                required: false
            },
            initErrors: {
                type: Object,
                required: false,
                default: () => { return {}; }
            }
        }
    }
</script>

<style scoped>

</style>