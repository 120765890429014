var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "uk-grid-small align-items-center",
          attrs: { "uk-grid": "" }
        },
        [
          _c("div", { staticClass: "uk-width-1-5" }, [
            _c("p", { staticClass: "my-0 text--small" }, [
              _vm._v(_vm._s(_vm.translations.quantity || "Aantal") + ":")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-width-2-5" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.quantity,
                  expression: "quantity"
                }
              ],
              staticClass: "custom-number-input mr-3",
              attrs: {
                inputmode: "numeric",
                max: _vm.product.available_stock,
                type: "number",
                name: "amount",
                min: "1",
                value: "1"
              },
              domProps: { value: _vm.quantity },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.quantity = $event.target.value
                }
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "add-to-cart-button",
        {
          staticClass: "uk-button uk-button-success mt-4",
          attrs: {
            route: _vm.route,
            "purchasable-id": _vm.product.id,
            "purchasable-type": _vm.product.purchasable_type,
            quantity: _vm.quantity
          }
        },
        [
          _c("img", {
            staticClass: "mr-3",
            attrs: {
              src: "/assets/images/icons/cart.svg",
              draggable: "false",
              "uk-svg": ""
            }
          }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.translations.addToCart || "In mijn winkelwagen") +
              "\n    "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }