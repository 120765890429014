<template>
    <ul class="side-menu">
        <li v-if="breadcrumbs.length > 0">
            <a @click.prevent="() => change('')" href="#" class="category-back"><span><i class="fas fa-chevron-left mr-2"></i> {{ translations.all_categories || 'Alle categorieën' }}</span></a>
        </li>
        <li v-for="(breadcrumb, index) in breadcrumbs">
            <a @click.prevent="() => change(breadcrumb)" href="#" v-if="index !== (breadcrumbs.length - 1)" class="category-back"><span><i class="fas fa-chevron-left mr-2"></i> {{ breadcrumb.name }}</span></a>
            <a href="#" v-else class="category-header"> {{ breadcrumb.name }}</a>
        </li>
        <li v-for="link in links">
            <a v-if="link.products === 0" :class="{'category-item': breadcrumbs.length > 0}" href="#" @click.prevent="() => change(link)">{{ link.name }} <font-awesome-icon :icon="['fas', 'chevron-right']" /></a>
            <a v-else :class="{'category-item': breadcrumbs.length > 0}" :href="link.route">{{ link.name }}</a>
        </li>
    </ul>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "NestedNavigation",
        data() {
            return {
                links: this.initLinks,
                breadcrumbs: this.initBreadcrumbs
            }
        },
        methods: {
            change(link) {
                let id = link === '' ? '' : link.id;

                if (link !== '' && this.useAsFilters) {
                    EventBus.$emit('TOGGLE_SLOT', 'product_list');
                    window.setTimeout(() => {
                        EventBus.$emit('LOAD_PRODUCTS_LIST', link.route, link.name);
                    }, 1);
                }

                axios.get(`${this.route}/${id}`)
                    .then(res => {
                        if (res?.data?.links) {
                            this.links = res.data.links;
                        }

                        if (res?.data?.breadcrumbs) {
                            this.breadcrumbs = res.data.breadcrumbs;
                        }
                    });
            }
        },
        props: {
            initLinks: {
                type: Array,
                default: () => []
            },
            initBreadcrumbs: {
                type: Array,
                default: () => []
            },
            route: {
                type: String,
                required: true
            },
            useAsFilters: {
                type: Boolean|Number,
                default: false
            },
            translations: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>