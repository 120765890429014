<template>
  <label
    class="switch"
  >
    <input
       type="checkbox"
       @change="toggle"
       v-model="val"
       :disabled="disabled ? true : false"
    >
    <span class="slider round" :class="{'slider--disabled': disabled}"></span>
  </label>
</template>
<script>
    export default {
        data() {
            return {
               val: this.value
            }
        },
        methods: {
            toggle() {
                window.setTimeout(() => {
                  this.$emit("input", this.val);
                }, 1);
            },
        },
        props: {
            value: {
                type: Number|Boolean,
                default: false,
                required: false
            },
            disabled: {
                type: Number|Boolean,
                default: false,
                required: false
            }
        }
    }
</script>

<style>

</style>