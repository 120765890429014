var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-sm-row flex-column justify-content-between align-items-center mb-sm-5 mb-4"
      },
      [
        _c(
          "div",
          { staticClass: "uk-width-1-4@m uk-width-1-3@s mb-sm-0 mb-4" },
          [
            _c("form", { staticClass: "form-custom" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.q,
                    expression: "q"
                  }
                ],
                staticClass: "uk-input search-input",
                attrs: {
                  type: "search",
                  name: "search",
                  placeholder: "ID, naam, trefwoord, ..."
                },
                domProps: { value: _vm.q },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.q = $event.target.value
                    },
                    _vm.search
                  ]
                }
              })
            ])
          ]
        )
      ]
    ),
    _vm._v(" "),
    _c(
      "table",
      {
        staticClass:
          "uk-table uk-table-divider uk-table-justify uk-table-hover uk-table-responsive"
      },
      [
        _c("thead", [_c("tr", [_vm._t("header")], 2)]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.items, function(item) {
            return _c("tr", [_vm._t("row", null, { item: item })], 2)
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }