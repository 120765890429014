<template>
    <div>
        <section class="uk-section uk-section-small pt-0">
            <div class="products-bar">
                <section>
                    <p class="my-sm-0 mb-2"><strong>{{ elementsTotal || products.length }}</strong> {{ translations.results }}</p>
                </section>

                <section>
                    <p v-if="sorting" class="my-0 mr-sm-4 mr-2 text--small">{{ translations.sort }}:</p>

                    <select v-if="sorting" name="product_sorting" v-model="sort" @change="handleSort" class="products-bar__sorting">
                        <option v-for="(method, key) in sorting" :value="key">{{ translations.sorting[method.lang_key] }}</option>
                    </select>

                    <div v-if="styleTypes.length > 1">
                        <a v-for="(type, index) in styleTypes"  @click.prevent="styleType = index" class="products-bar__toggle noselect ml-4" :class="{'products-bar__toggle--active': styleType == index}">
                            <img :src="`/assets/images/icons/products-${type}.svg`" draggable="false" uk-svg />
                        </a>
                    </div>
                </section>
            </div>
        </section>

        <section class="uk-section uk-section-small pt-0">
            <div v-if="loading" class="uk-width-1-1 uk-flex uk-flex-center">
                <div uk-spinner="ratio: 2.5"></div>
            </div>
            <div v-else :class="`products-${styleTypes[styleType]}`">
                <div
                    v-on:list-updated="list => products = list"
                    v-for="item in products"
                    :is="`${component}-${styleTypes[styleType]}`"
                    v-bind="passThroughProps"
                    :product="item"
                    :key="item.id"
                ></div>
            </div>
        </section>
    </div>
</template>

<script>
    import { EventBus } from "../EventBus";
    import { changeUrl } from "../functions";

    export default {
        name: "ProductList",
        created() {
            this.$options.styles = this.styleTypes;
        },
        mounted() {
            EventBus.$on('CHANGE_ITEMS_' + this.identifier, items => {
                this.products = items;
            });

            EventBus.$on('LOAD_PRODUCTS_' + this.identifier, (link, name) => {
                this.loadProducts(link);
                EventBus.$emit(`UPDATE_${this.identifier}`, name);
            });
        },
        data() {
            return {
                styleType: this.getDefaultStyleType(),
                products: this.items,
                sort: this.initSort(),
                elementsTotal: this.total ?? 0,
                loading: false
            }
        },
        computed: {
            passThroughProps() {
                const data = this.$props.componentData;
                data.styleType = this.$options.styles[this.styleType];
                return data;
            }
        },
        watch: {
            styleType() {
                window.sessionStorage.setItem('style-type', this.$options.styles[this.styleType]);
            }
        },
        methods: {
            initSort() {
                const params = new URLSearchParams(window.location.search);
                return params.get('sort') || Object.keys(this.sorting)[0];
            },
            getDefaultStyleType() {
                const styles = this.styleTypes;
                const i = _.indexOf(styles, window.sessionStorage.getItem('style-type'));
                if (i < 0) {
                    return 0;
                }

                return i;
            },
            loadProducts(url) {
                this.startLoading();
                this.sort = this.initSort();

                axios.get(changeUrl(changeUrl(url, 'sort', this.sort), 'page', 1))
                    .then(res => {
                        if (res?.data?.data) {
                            this.products = res.data.data;
                            this.elementsTotal = res.data.total;
                            EventBus.$emit(`PAGINATOR_${this.identifier}_CHANGED`, res.data);
                        }

                        this.stopLoading();
                    });
            },
            handleSort() {
                if (this.ajaxReload) {
                    this.startLoading();
                    axios.get(changeUrl(changeUrl(window.location.href, 'sort', this.sort), 'page', 1))
                        .then(res => {
                            if (res?.data?.data) {
                                this.products = res.data.data;
                                EventBus.$emit(`PAGINATOR_${this.identifier}_CHANGED`, res.data);
                            }

                            this.stopLoading();
                        });
                } else {
                    window.location.href = changeUrl(changeUrl(window.location.href, 'sort', this.sort), 'page', 1);
                }
            },
            startLoading() {
                this.loading = true;
            },
            stopLoading() {
                this.loading = false;
            },
            changeUrl
        },
        props: {
            component: {
                type: String,
                required: true,
            },
            items: {
                type: Array|Object,
                required: false,
                default: () => { return [] }
            },
            componentData: {
                type: [Array, Object],
                required: true
            },
            identifier: {
                type: String,
                default: 'LIST',
                required: false
            },
            total: {
                type: Number,
                required: false,
                default: null
            },
            translations: {
                type: Object,
                required: true
            },
            sorting: {
                type: Object|Array,
                required: false,
                default: null
            },
            ajaxReload: {
                type: Boolean|Number,
                required: false,
                default: true
            },
            styleTypes: {
                type: Array,
                default: () => {return [];}
            }
        }
    }
</script>

<style scoped>

</style>