var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag || "a",
    {
      tag: "component",
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.confirm(_vm.body, _vm.clickHandler)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }