var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "switch" }, [
    _c("input", {
      directives: [
        { name: "model", rawName: "v-model", value: _vm.val, expression: "val" }
      ],
      attrs: { type: "checkbox", disabled: _vm.disabled ? true : false },
      domProps: {
        checked: Array.isArray(_vm.val) ? _vm._i(_vm.val, null) > -1 : _vm.val
      },
      on: {
        change: [
          function($event) {
            var $$a = _vm.val,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.val = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.val = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.val = $$c
            }
          },
          _vm.toggle
        ]
      }
    }),
    _vm._v(" "),
    _c("span", {
      staticClass: "slider round",
      class: { "slider--disabled": _vm.disabled }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }