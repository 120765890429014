<template>
    <h5>{{ absolute }},<sup>{{ decimals }}</sup></h5>
</template>

<script>
    export default {
        name: "Price",
        computed: {
            absolute() {
                return _.split(this.price, ',')[0];
            },
            decimals() {
                const deci = _.split(this.price, ',')[1];
                if (deci == '0' || deci == '' || deci == '00' || deci == null) {
                    return '-';
                }

                return deci;
            }
        },
        props: {
            price: {
                required: true
            }
        }
    }
</script>

<style scoped>

</style>