<template>
    <section id="left">
        <div id="top-bar-search">
            <input
                type="search"
                name="main-search"
                id="main-search"
                :placeholder="translations.placeholder | capitalize"
                v-model="q"
                @input="searchTypeHandler"
            >
        </div>

        <div v-if="(products.length > 0 || categories.length > 0) && q.length > 0" class="top-bar-search-results">
            <a v-for="category in categories" :href="category.url" class="category">
                {{ category.name }}
                <i class="fas fa-arrow-right"></i>
            </a>

            <a v-for="product in products" :href="product.url" class="product">
                <span class="image">
                    <img :data-src="product.img" :alt="product.name" uk-img/>
                </span>

                <span class="content">
                    <span class="sku">{{ product.sku }}</span>
                    <p>{{ product.name }}</p>
                </span>
            </a>

            <hr class="uk-margin-remove">
            <div class="p-4 uk-width-1-1">
                <a :href="`/search?q=${q}`" class="uk-width-1-1 uk-button uk-button-primary uk-text-center">{{ translations.all_products || 'Toon alle producten' }}</a>
            </div>
        </div>
        <div v-else-if="q.length > 0" class="top-bar-search-results uk-padding-small">
            <strong>{{ translations.searchEmpty }}</strong>
        </div>
    </section>
</template>

<script>
    import debounce from 'debounce';
    import { EventBus } from "../EventBus";

    export default {
        name: "SearchBar",
        data() {
            return {
                q: '',
                results: {}
            }
        },
        created() {
            this.searchTypeHandler = debounce(this.searchTypeHandler, 300);
        },
        computed: {
            products() {
                return this?.results?.products ?? [];
            },
            categories() {
                return this?.results?.categories ?? [];
            }
        },
        methods: {
            debounce,
            searchTypeHandler() {
                axios.get(`${this.route}?q=${this.q}`)
                    .then(res => {
                        if (res?.data) {
                            this.results = res.data;
                        }
                    });
            }
        },
        filters: {
            capitalize: function (value) {
                if (!value) return ''
                value = value.toString()
                return value.charAt(0).toUpperCase() + value.slice(1)
            }
        },
        props: ['route', 'translations']
    }
</script>

<style scoped>

</style>