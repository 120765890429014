var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "products-row-item row align-items-center" },
    [
      _vm.item.purchasable.discount_percentage > 0
        ? _c(
            "div",
            {
              staticClass: "promo-banner uk-visible@m ml-5 uk-position-z-index"
            },
            [
              _vm._v(
                "- " + _vm._s(_vm.item.purchasable.discount_percentage) + "%"
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "col-1 mb-md-0 mb-4" }, [
        _c(
          "button",
          {
            staticClass: "button__delete",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.deleteItem($event)
              }
            }
          },
          [_c("i", { staticClass: "fas fa-times" })]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2 col-3 mb-md-0 mb-4" }, [
        _c("img", {
          attrs: {
            "data-src": _vm.getImage(_vm.item),
            alt: "Placeholder product 1",
            draggable: "false",
            width: "100",
            "uk-img": ""
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-5 col-sm-9 col-8 mb-md-0 mb-4" }, [
        _c("div", { staticClass: "d-flex flex-column" }, [
          _c("p", { staticClass: "mb-1 sku" }, [
            _vm._v(
              _vm._s(_vm.translations.product.artnr || "") +
                ": " +
                _vm._s(_vm.item.purchasable.external_code)
            )
          ]),
          _vm._v(" "),
          _c("h3", { staticClass: "mt-0 mb-2" }, [
            _vm._v(_vm._s(_vm.item.purchasable.name))
          ])
        ]),
        _vm._v(" "),
        _vm.errors && _vm.errors.quantity
          ? _c("p", { staticClass: "uk-text-danger" }, [
              _vm._v(
                "\n            " + _vm._s(_vm.errors.quantity[0]) + "\n        "
              )
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-md-2 col-sm-5 col-8 offset-md-0 offset-sm-1 offset-4 mb-sm-0 mb-4"
        },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-md-end align-items-center" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.quantity,
                    expression: "quantity",
                    modifiers: { number: true }
                  }
                ],
                staticClass: "custom-number-input mr-3",
                attrs: {
                  type: "number",
                  name: "amount",
                  max: _vm.item.purchasable.available_stock,
                  min: "1",
                  value: "1"
                },
                domProps: { value: _vm.quantity },
                on: {
                  change: _vm.updateItem,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.quantity = _vm._n($event.target.value)
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _c("stock-indication", {
                attrs: {
                  mode: "compact",
                  stock: _vm.item.purchasable.stock,
                  translations: _vm.translations.stock || {},
                  threshold: _vm.stockThreshold || 5,
                  date: _vm.item.purchasable.restock_date_formatted || null,
                  "with-text": false
                }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-2 col-sm-6 col-8 offset-4 offset-sm-0" },
        [
          _c(
            "div",
            { staticClass: "d-flex justify-content-sm-end" },
            [
              _c("price", {
                staticClass: "my-0 mr-2 uk-h3",
                attrs: { price: _vm.item.total_formatted }
              })
            ],
            1
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }