<template>
    <form class="uk-margin-medium-top">
        <table class="uk-width-expand">
            <thead>
                <tr class="uk-text-left">
                    <th class="uk-padding-small-bottom">{{ translations.price || '' }}</th>
                    <th class="uk-padding-small-bottom">{{ translations.size || '' }}</th>
                    <th class="uk-padding-small-bottom">{{ translations.quantity || '' }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(product, index) in products">
                    <td>{{ product.price_formatted }} / {{ product.unit }}</td>
                    <td>{{ product.size || 0 }}</td>
                    <td>
                        <input type="number" min="1" v-model.number="list[index].quantity" id="quantity" class="uk-input product-card__input--number uk-margin-small-right">

                        <div class="uk-inline-block uk-text-middle" v-if="product.stock > 0">
                            <span class="uk-text-large"><i class="uk-text-success fa fa-check-circle"></i></span>
                        </div>
                        <div class="uk-inline-block uk-text-middle " v-else-if="product.stock < 1 && product.restock_date != null">
                            <span><i class="uk-text-blue uk-text-large fa fa-circle"></i></span>
                            <span class="uk-margin-small-left">({{ product.restock_date_formatted }})</span>
                        </div>
                        <div class="uk-inline-block" v-else>
                            <span class="uk-text-large"><i class="uk-text-danger fa fa-times-circle"></i></span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

        <button @click.prevent="addToCart" class="uk-button uk-button uk-button-secondary uk-margin-medium-top uk-margin-small-bottom">
            {{ translations.addToCart || '' }}
        </button>
    </form>
</template>

<script>
    import { EventBus } from "../EventBus";

    export default {
        name: "AddMultipleToCartComponent",
        data() {
            return {
                list: this.products.map(product => {
                    return {
                        purchasable_id: product.id,
                        purchasable_type: product.purchasable_type,
                        quantity: 0
                    }
                })
            }
        },
        methods: {
            addToCart() {
                const products = this.list.filter(item => {
                    return item.quantity > 0;
                }).map(item => {
                    return {
                        type: item.purchasable_type,
                        id: item.purchasable_id,
                        quantity: item.quantity
                    }
                });

                window.axios.post(this.route, {purchasables: products})
                    .then(res => {
                        if (res.data && res.data.cart) {
                            EventBus.$emit('CART_UPDATED', res.data.cart);
                        }

                        if (res.data && res.data.message) {
                            EventBus.$emit('NOTIFY', {
                                message: res.data.message,
                                status: 'success'
                            });
                        }

                        this.$emit('add-success');
                    })
                    .then (() => {
                        this.list.forEach(item => {
                            item.quantity = 0;
                        });
                    })
                    .catch(err => {
                        EventBus.$emit('NOTIFY_ERROR');
                    });
            }
        },
        props: {
            products: {
                type: Array,
                required: true
            },
            translations: {
                type: Object,
                required: true
            },
            route: {
                type: String,
                required: true
            }
        }

    }
</script>

<style scoped>

</style>