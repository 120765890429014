<template>
    <div class="uk-form-controls">
        <div class="uk-width-1-1 uk-margin-small-bottom" v-if="label">
            <label :for="id || `${_uid}-${name}`" class="mr-sm-4 mb-sm-0 mb-3 form-custom__label">{{ label }}</label>
        </div>
        <ckeditor
            v-if="visible"
            ref="editor"
            :id="id || `${_uid}-${name}`"
            :config="$options.editorConfig"
            v-model="val"
            @input="$emit('input', val)"
        ></ckeditor>
        <p
            v-if="errors.length > 0"
            class="text--small uk-form-danger mt-2"
        >
            <strong>{{ errors[0] }}</strong>
        </p>
    </div>
</template>

<script>
    export default {
        name: "CkeditorField",
        beforeMount() {
            this.$options.editorConfig = {
                toolbar: this.customToolbar ?? [
                    ['Format', 'Styles'],
                    ['Bold', 'Italic'],
                    [
                        'RemoveFormat', 'NumberedList',
                        'BulletedList', 'Unlink',
                        'Anchor', 'Embed',
                        'Table', 'PasteText',
                        'PasteFromWord', 'Scayt'
                    ]
                ],
                stylesSet: [
                    {name: 'Gestijlde tabel', element: 'table', attributes: {'class': 'uk-table uk-width-expand uk-table-divider uk-table-middle', 'border': 0, 'cellpadding': 0, 'cellspacing': 0, 'style': 'width: 100%;'}}
                ]
            };
        },
        mounted() {
            this.interval = window.setInterval(() => {
                if (this.$el.offsetParent !== null) {
                    this.visible = true;
                    window.clearInterval(this.interval);
                }
            }, 500);
        },
        data() {
            return {
                val: this.value,
                errors: this.initErrors,
                visible: false,
            }
        },
        watch: {
            initErrors (newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            name: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: false,
            },
            label: {
                type: String,
                required: false,
                default: null
            },
            value: {
                type: String,
                required: false,
                default: ''
            },
            initErrors: {
                type: Array,
                required: false,
                default: () => { return []; }
            },
            customToolbar: {
                type: Array,
                required: false,
                default: null
            }
        }
    }
</script>

<style scoped>

</style>