<template>
    <form>
        <multi-language-field
            :locales="locales"
            v-model="input"
            :init-errors="only(errors, ['title'])"
            label="Titel"
        >
            <template v-slot:default="slotProps">
                <text-field
                    :init-errors="slotProps.errors.title || []"
                    :name="dotToName(`${fieldPrefix}.title`)"
                    :value="slotProps.title"
                    @input="value => slotProps.handleInput('title', value)"
                ></text-field>
            </template>
        </multi-language-field>

        <multi-language-field
            :locales="locales"
            v-model="input"
            @input="$emit('input', input)"
            :init-errors="only(errors, ['body'])"
            label="Inhoud"
        >
            <template v-slot:default="slotProps">
                <ckeditor-field
                    :init-errors="slotProps.errors.body || []"
                    :name="dotToName(`${fieldPrefix}.body`)"
                    :value="slotProps.body"
                    @input="value => slotProps.handleInput('body', value)"
                ></ckeditor-field>
            </template>
        </multi-language-field>

        <toggle-field
            label="Grote knoppen"
            name="big"
            v-model="input.big"
            :init-errors="errors.big || []"
        >
        </toggle-field>

        <repeater-field
            :types="['Link']"
            v-model="input.links"
            :init-errors="errors.links"
            label="Links"
            :max="3"
        >
            <template v-slot:Link="slotProps">
                <multi-language-field
                    :value="slotProps.input"
                    :locales="locales"
                    label="Label *"
                    :init-errors="slotProps.errors || {}"
                    @input="value => slotProps.handleInput(null, value)"
                >
                    <template v-slot:default="slotProps">
                        <text-field
                            class="uk-margin-medium-bottom"
                            :init-errors="slotProps.errors.label || []"
                            :name="dotToName(`${fieldPrefix}.label`)"
                            :value="slotProps.label"
                            @input="value => slotProps.handleInput('label', value)"
                        ></text-field>
                    </template>
                </multi-language-field>

                <multi-language-field
                    :value="slotProps.input"
                    :locales="locales"
                    label="Url *"
                    :init-errors="slotProps.errors || {}"
                    @input="value => slotProps.handleInput(null, value)"
                >
                    <template v-slot:default="slotProps">
                        <text-field
                            class="uk-margin-medium-bottom"
                            :init-errors="slotProps.errors.url || []"
                            :name="dotToName(`${fieldPrefix}.url`)"
                            :value="slotProps.url"
                            @input="value => slotProps.handleInput('url', value)"
                        ></text-field>
                    </template>
                </multi-language-field>

                <div uk-grid>
                    <div class="uk-width-1-2">
                        <select-field
                            label="Type *"
                            name="type"
                            required="required"
                            :value="slotProps.input.type || 'primary'"
                            :init-errors="(slotProps.errors || {}).type || []"
                            @input="value => slotProps.handleInput('type', value)"
                        >
                            <option value="primary">Primair</option>
                            <option value="secondary">Secundair</option>
                            <option value="tertiary">Tertiair</option>
                        </select-field>
                    </div>
                </div>
            </template>
        </repeater-field>
    </form>
</template>

<script>
    import TextField from "../Fields/TextField";
    import TextareaField from "../Fields/TextareaField";
    import { dotToName } from "../../functions";
    import UploadField from "../Fields/UploadField";
    import MultiLanguageField from "../Fields/MultiLanguageField";
    import SelectField from "../Fields/SelectField";

    export default {
        name: "TextBlock",
        components: {SelectField, MultiLanguageField, UploadField, TextareaField, TextField},
        data() {
            return {
                block: this.initBlock,
                input: this.initInput,
                errors: this.initErrors,
            }
        },
        methods: {
            dotToName: dotToName,
            only(object, keys = []) {
                return _.mapValues(object, lang => {
                    return _.pick(lang, keys);
                });
            },
        },
        watch: {
            initErrors(newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            initBlock: {
                type: Object,
                required: true
            },
            fieldPrefix: {
                type: String,
                required: false,
                default: ''
            },
            initInput: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            initErrors: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            locales: {
                type: Object,
                required: false,
                default: () => { return []; }
            },
            routes: {
                type: Object,
                required: false,
                default: () => { return {} }
            }
        }
    }
</script>