<template>
    <div class="uk-form-controls">
        <div class="uk-width-1-1 uk-margin-small-bottom" v-if="label">
            <label :for="id || `${_uid}-${name}`" class="mr-sm-4 mb-sm-0 mb-3 form-custom__label">{{ label }}</label>
        </div>
        <label class="switch" @click.prevent.stop="toggle">
            <input type="checkbox" :checked="val">
            <span class="slider round"></span>
        </label>
        <p
            v-if="errors.length > 0"
            class="text--small uk-form-danger mt-2"
        >
            <strong>{{ errors[0] }}</strong>
        </p>
    </div>
</template>

<script>
    import ToggleInput from "../ToggleInput";
    export default {
        name: "ToggleField",
        components: {ToggleInput},
        data() {
            return {
                val: this.value || false,
                errors: this.initErrors
            }
        },
        methods: {
            toggle() {
                this.val = !this.val;
                this.$emit('input', this.val);
            }
        },
        watch: {
            initErrors (newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            name: {
                type: String,
                required: true
            },
            id: {
                type: String,
                required: false,
            },
            label: {
                type: String,
                required: false,
                default: null
            },
            required: {
                type: Boolean,
                required: false,
                default: false
            },
            placeholder: {
                type: String,
                required: false,
                default: ''
            },
            value: {
                type: Boolean|String,
                required: false,
                default: ''
            },
            initErrors: {
                type: Array,
                required: false,
                default: () => { return []; }
            }
        }
    }
</script>

<style scoped>

</style>