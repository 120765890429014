var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "products-row-item row align-items-center" },
    [
      _vm.product.discount > 0
        ? _c("div", { staticClass: "promo-banner uk-visible@m" }, [
            _vm._v("- " + _vm._s(_vm.product.discount_percentage) + "%")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-md-1 col-sm-2 col-6 offset-md-1 offset-sm-0 offset-3 mb-md-0 mb-4"
        },
        [
          _c("a", { attrs: { href: _vm.detailRoute } }, [
            _c("img", {
              attrs: {
                "data-src": _vm.firstImage,
                alt: _vm.product.name,
                draggable: "false",
                "uk-img": ""
              }
            })
          ])
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-3 col-sm-5 mb-md-0 mb-4" }, [
        _c("div", { staticClass: "d-flex flex-column" }, [
          _c("a", { attrs: { href: _vm.detailRoute } }, [
            _c("p", { staticClass: "mb-1 products-row-item__sku" }, [
              _vm._v(
                _vm._s(_vm.translations.sku) +
                  ": " +
                  _vm._s(_vm.product.external_code)
              )
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "mb-0 mt-0 products-row-item__name" }, [
              _vm._v(_vm._s(_vm.product.name))
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-4 col-sm-6 mb-sm-0 mb-4" }, [
        _c(
          "div",
          { staticClass: "d-flex justify-content-md-end align-items-center" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.quantity,
                  expression: "quantity",
                  modifiers: { number: true }
                }
              ],
              staticClass: "custom-number-input mr-3",
              attrs: {
                type: "number",
                name: "amount",
                max: parseInt(_vm.product.available_stock),
                min: "1",
                value: "1"
              },
              domProps: { value: _vm.quantity },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.quantity = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _c("stock-indication", {
              attrs: {
                mode: "compact",
                stock: _vm.product.stock,
                translations: _vm.translations.stock || {},
                threshold: _vm.stockThreshold,
                date: _vm.product.restock_date_formatted || null,
                "with-text": false
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-1 col-sm-4 col-9" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex flex-column align-items-sm-end align-items-md-start"
          },
          [
            _c(
              "div",
              { staticClass: "d-flex align-items-baseline" },
              [
                _c("price", {
                  staticClass: "text-small my-0",
                  attrs: { price: _vm.product.price_formatted }
                }),
                _vm._v(" "),
                _vm.product.discount > 0
                  ? _c("p", { staticClass: "my-0 text--small" }, [
                      _vm._v(
                        "/ " + _vm._s(_vm.product.unit || _vm.translations.unit)
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.product.discount > 0
              ? _c("p", { staticClass: "my-0 text--small text--muted" }, [
                  _c("s", [
                    _vm._v(
                      "€ " +
                        _vm._s(
                          _vm.format_price(_vm.product.original_price_formatted)
                        )
                    )
                  ])
                ])
              : _vm._e()
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-2 col-3" }, [
        _c(
          "div",
          { staticClass: "uk-flex uk-flex-middle" },
          [
            _vm.showProduct(_vm.product)
              ? _c(
                  "add-to-cart-button",
                  {
                    staticClass: "button__cart",
                    attrs: {
                      route: _vm.routes.addToCart,
                      "purchasable-type": _vm.product.purchasable_type,
                      "purchasable-id": _vm.product.id,
                      quantity: _vm.quantity
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/assets/images/icons/cart.svg",
                        draggable: "false",
                        height: "18",
                        "uk-svg": ""
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "favorite-button uk-margin-small-left",
                on: { click: _vm.toggleFavorites }
              },
              [
                _c("font-awesome-icon", {
                  staticClass:
                    "uk-text-large uk-text-middle uk-margin-small-right",
                  attrs: { icon: [_vm.inFavorites ? "fas" : "far", "heart"] }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }