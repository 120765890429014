<template>
    <form>
        <slot></slot>

        <ul class="uk-grid-small uk-child-width-1-1" ref="sortable" id="sortable" uk-grid>
            <li v-for="e in blocks" :key="e.id" :data-id="e.id">
                <ul class="page-accordion uk-background-white" uk-accordion="toggle: .indicator, .block-name">
                    <li>
                        <a class="uk-accordion-title">
                            <div class="d-flex align-items-center">
                                <div class="handler mr-3"><img src="/assets/images/icons/actions/move.svg" draggable="false" uk-svg></div>
                                <p class="block-name my-0"><strong>{{e.template.name || ''}}</strong></p>
                                <span v-if="!empty(errors[e.id])">&nbsp; <i class="fa fa-exclamation-circle"></i></span>
                            </div>

                            <div class="d-flex justify-content-end align-items-center">
                                <div class="indicator mr-3"><i class="fas fa-chevron-right"></i></div>

                                <label class="switch small mr-2">
                                    <input type="checkbox" :checked="e.active == 1" @change="() => toggleBlock(e.id)">
                                    <span class="slider round"></span>
                                </label>

                                <button @click.prevent="confirm('Ben je zeker dat je dit blok wil verwijderen?', () => deleteBlock(e))"><img src="/assets/images/icons/actions/delete.svg" draggable="false" uk-svg></button>
                            </div>
                        </a>

                        <div class="uk-accordion-content">
                            <form
                            class="form-custom uk-grid-small uk-child-width-1-1" uk-grid
                            uk-grid
                            v-bind:is="e.component"
                            v-model="input[e.id]"
                            :init-errors="errors[e.id] || {}"
                            :init-block="e"
                            :init-input="input[e.id]"
                            :routes="routes"
                            :locales="locales"
                            :field-prefix="`blocks.${e.id}`">
                            </form>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>

        <div class="d-flex flex-sm-row flex-column align-items-sm-center mt-5">
            <button @click.prevent="savePageContent" :disabled="isLoading" class="uk-button uk-button-primary mr-sm-3 mb-sm-0 mb-3">
                <div v-if="isLoading" uk-spinner></div>
                Opslaan
            </button>
            <button class="uk-button uk-button-secondary mr-sm-3 mb-sm-0 mb-3">Annuleren</button>
            <h5 v-if="!empty(errors)" class="my-0 uk-text-danger uk-text-center uk-text-left@s">Gelieve de velden correct in te vullen a.u.b.</h5>
        </div>
    </form>
</template>

<script>
    import { EventBus } from "../EventBus";
    import dot from 'dot-object';
    import { confirm } from "../functions";
    import empty from 'lodash/isEmpty';

    export default {
        name: "PageContent",
        mounted() {
            EventBus.$on('PAGE_CONTENT_CHANGED', content => {
                this.blocks = content;
                this.input =  this.initBlockInput(content);
            });

            this.sortable = UIkit.sortable(this.$refs.sortable, {
                handle: '.handler',
            });

            UIkit.util.on(this.$refs.sortable, 'moved', (e) => this.handleSort(e));
        },
        data() {
            return {
                blocks: this.content,
                input: this.initBlockInput(),
                isLoading: false,
                errors: {}
            }
        },
        methods: {
            empty,
            confirm,
            toggleBlock(id) {
                window.axios.put(this.routes.active.replace('-id-', id));
            },
            initBlockInput(blocks = null) {
                return _(blocks == null ? this.content : blocks)
                    .keyBy(item => {
                        return item.id;
                    })
                    .mapValues(item => {
                        if (typeof item.content === 'object' && item.content !== null) {
                            return item.content;
                        }

                        return {};
                    })
                    .value();
            },
            handleSort(e) {
                const ids = _.map(e.target.children, (item) => {
                    return item.dataset.id;
                });

                this.blocks = _.sortBy(this.blocks, (item) => {
                    return _.indexOf(ids, item.id.toString());
                });

                this.postSort();
            },
            postSort() {
                window.axios.post(this.routes.sort, {
                    items: _.map(this.blocks, 'id')
                })
                    .then(res => {
                        this.blocks = res.data.content;
                        EventBus.$emit('PAGE_CONTENT_CHANGED', this.blocks);
                    });
            },
            deleteBlock(block) {
                const url = this.routes.delete.replace('-id-', block.id);

                axios.post(url, {_method: 'DELETE'})
                    .then(res => {
                        if (res.data.blocks) {
                            EventBus.$emit('PAGE_CONTENT_CHANGED', res.data.blocks);
                        }

                        if(res.data && res.data.message) {
                            EventBus.$emit('NOTIFY', res.data.message);
                        }
                    })
                    .catch(err => {
                        if (err.response.data && err.response.data.message) {
                            EventBus.$emit('NOTIFY', err.response.data.message);
                        }
                    });
            },
            savePageContent() {
                this.isLoading = true;
                axios.post(this.routes.update, {blocks: this.input, _method: 'PUT', locale: this.locale})
                    .then(res => {
                        if(res.data && res.data.message) {
                            EventBus.$emit('NOTIFY', res.data.message);
                        }

                        this.isLoading = false;
                        this.errors = {};
                    })
                    .catch(err => {
                        if (err.response && err.response.data && err.response.data.errors && err.response.data.errors) {
                            const errors = dot.object(err.response.data.errors);
                            if (errors.blocks) {
                                this.errors = _.pickBy(errors.blocks, block => {
                                    return block != null;
                                });
                            }
                        }

                        if(err.response.data && err.response.data.message) {
                            EventBus.$emit('NOTIFY', err.response.data.message);
                        }

                        this.isLoading = false;
                    });
            }
        },
        props: {
            content: {
                type: Array,
                default: []
            },
            routes: {
                type: Object,
                default: () => { return {}; }
            },
            locales: {
                type: Object,
                required: true
            }
        }
    }
</script>