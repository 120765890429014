<template>
    <input
        type="text"
       :class="className"
       :name="name"
       :id="id"
       :value="value"
       ref="datepicker"
       autocomplete="off"
       @input="e => handleInput(e)"
    >
</template>

<script>
    import flatpickr from "flatpickr";
    import { lang_nl } from 'flatpickr/dist/l10n/nl.js'
    import { lang_fr } from 'flatpickr/dist/l10n/fr.js'
    import { lang_it } from 'flatpickr/dist/l10n/it.js'
    import { lang_es } from 'flatpickr/dist/l10n/es.js'
    import { lang_de } from 'flatpickr/dist/l10n/de.js'

    export default {
        name: "DatePicker",
        mounted() {
            if(this.$refs.datepicker) {
                flatpickr(this.$refs.datepicker, {
                    enableTime: false,
                    minDate: 'today',
                    time_24hr: true,
                    allowInput: true,
                    defaultDate: null,
                    locale: this.locale,
                    dateFormat: 'd-m-Y'
                });
            }
        },
        data() {
            return {
                val: this.value
            }
        },
        methods: {
            handleInput(e) {
                this.val = e.currentTarget.value;
                this.$emit('input', this.val);
            }
        },
        props: {
            className: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: '',
            },
            value: {
                type: String,
                default: null,
            },
            id: {
                type: String,
                default: '',
            },
            locale: {
                type: String,
                default: window.locale ?? 'nl'
            }
        }
    }
</script>

<style scoped>

</style>