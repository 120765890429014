var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _c("multi-language-field", {
        attrs: {
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["title"]),
          label: "Titel"
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("text-field", {
                  attrs: {
                    "init-errors": slotProps.errors.title || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".title"),
                    value: slotProps.title
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("title", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm._v(" "),
      _c("multi-language-field", {
        attrs: {
          locales: _vm.locales,
          "init-errors": _vm.only(_vm.errors, ["body"]),
          label: "Inhoud *"
        },
        on: {
          input: function($event) {
            return _vm.$emit("input", _vm.input)
          }
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(slotProps) {
              return [
                _c("ckeditor-field", {
                  attrs: {
                    value: slotProps.body,
                    "init-errors": slotProps.errors.body || [],
                    name: _vm.dotToName(_vm.fieldPrefix + ".body"),
                    placeholder: "Lorem ipsum dolor sit amet..."
                  },
                  on: {
                    input: function(value) {
                      return slotProps.handleInput("body", value)
                    }
                  }
                })
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm._v(" "),
      _c("upload-field", {
        attrs: {
          multi: false,
          collection: "image",
          label: "Foto",
          name: "image",
          "link-identifier": _vm.block.id + "-image",
          routes: {
            upload: _vm.routes.upload.replace("-id-", _vm.block.id),
            fetch: _vm.routes.getUploads.replace("-id-", _vm.block.id),
            sort: _vm.routes.sortUploads.replace("-id-", _vm.block.id),
            delete: _vm.routes.deleteUpload.replace("-id-", _vm.block.id)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }