<template>
    <div>
        <div class="d-flex flex-sm-row flex-column justify-content-between align-items-center mb-sm-5 mb-4">
            <div class="uk-width-1-4@m uk-width-1-3@s mb-sm-0 mb-4">
                <form class="form-custom">
                    <input type="search" v-model="q" @input="search" name="search" class="uk-input search-input" placeholder="ID, naam, trefwoord, ...">
                </form>
            </div>
        </div>

        <table class="uk-table uk-table-divider uk-table-justify uk-table-hover uk-table-responsive">
            <thead>
                <tr>
                    <slot name="header"></slot>
                </tr>
            </thead>

            <tbody>
                <tr v-for="item in items">
                    <slot name="row" v-bind:item="item"></slot>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import debounce from 'debounce';
    import { EventBus } from "../EventBus";

    export default {
        name: "DynamicTable",
        data() {
            return {
                items: this.initItems,
                q: ''
            }
        },
        mounted() {
            EventBus.$on('CHANGE_ITEMS_' + this.identifier, items => {
                this.items = items;
            });
        },
        created() {
            this.search = debounce(this.search, 250);
        },
        methods: {
            search() {
                if (this.q.length < 3 && this.q.length > 0) {
                    return;
                }

                window.axios.get(this.searchRoute, {params: {q : this.q}})
                    .then(res => {
                        this.items = res.data.data;
                        if (this.withPaginator) {
                            EventBus.$emit(`PAGINATOR_${this.identifier}_CHANGED`, res.data);
                        }
                    })
            }
        },
        props: {
            searchRoute: {
                type: String,
                default: null
            },
            withPaginator: {
                type: Boolean,
                default: false
            },
            identifier: {
                type: String,
                required: false,
                default: 'table'
            },
            initItems: {
                type: Array,
                required: false,
                default() { return []; }
            }
        }
    }
</script>

<style scoped>

</style>