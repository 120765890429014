<template>
    <form>
        <multi-language-field
            :locales="locales"
            v-model="input"
            :init-errors="only(errors, ['title'])"
            label="Titel"
        >
            <template v-slot:default="slotProps">
                <text-field
                    :init-errors="slotProps.errors.title || []"
                    :name="dotToName(`${fieldPrefix}.title`)"
                    :value="slotProps.title"
                    @input="value => slotProps.handleInput('title', value)"
                ></text-field>
            </template>
        </multi-language-field>

        <multi-language-field
            :locales="locales"
            v-model="input"
            @input="$emit('input', input)"
            :init-errors="only(errors, ['body'])"
            label="Inhoud *"
        >
            <template v-slot:default="slotProps">
                <ckeditor-field
                    :value="slotProps.body"
                    :init-errors="slotProps.errors.body || []"
                    :name="dotToName(`${fieldPrefix}.body`)"
                    @input="value => slotProps.handleInput('body', value)"
                    placeholder="Lorem ipsum dolor sit amet..."
                >
                </ckeditor-field>
            </template>
        </multi-language-field>

        <upload-field
            :multi="false"
            collection="image"
            label="Foto"
            name="image"
            :link-identifier="`${block.id}-image`"
            :routes="{
                upload: routes.upload.replace('-id-', block.id),
                fetch: routes.getUploads.replace('-id-', block.id),
                sort: routes.sortUploads.replace('-id-', block.id),
                delete: routes.deleteUpload.replace('-id-', block.id),
            }"
        ></upload-field>
    </form>
</template>

<script>
    import TextField from "../Fields/TextField";
    import { dotToName } from "../../functions";
    import TextareaField from "../Fields/TextareaField";
    import MultiLanguageField from "../Fields/MultiLanguageField";

    export default {
        name: "PhotoTextBlock",
        components: {MultiLanguageField, TextareaField, TextField},
        data() {
            return {
                block: this.initBlock,
                input: this.initInput,
                errors: this.initErrors,
            }
        },
        methods: {
            dotToName: dotToName,
            only(object, keys = []) {
                return _.mapValues(object, lang => {
                    return _.pick(lang, keys);
                });
            },
        },
        watch: {
            initErrors(newVal, oldVal) {
                this.errors = newVal;
            }
        },
        props: {
            initBlock: {
                type: Object,
                required: true
            },
            fieldPrefix: {
                type: String,
                required: false,
                default: ''
            },
            initInput: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            initErrors: {
                type: Object,
                required: false,
                default: () => { return {} }
            },
            locales: {
                type: Object,
                required: false,
                default: () => { return []; }
            },
            routes: {
                type: Object,
                required: false,
                default: () => { return {} }
            }
        }
    }
</script>