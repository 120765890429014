var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "ul",
        {
          ref: "sortable",
          staticClass: "uk-grid-small uk-child-width-1-1",
          attrs: { id: "sortable", "uk-grid": "" }
        },
        _vm._l(_vm.blocks, function(e) {
          return _c("li", { key: e.id, attrs: { "data-id": e.id } }, [
            _c(
              "ul",
              {
                staticClass: "page-accordion uk-background-white",
                attrs: { "uk-accordion": "toggle: .indicator, .block-name" }
              },
              [
                _c("li", [
                  _c("a", { staticClass: "uk-accordion-title" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c("p", { staticClass: "block-name my-0" }, [
                        _c("strong", [_vm._v(_vm._s(e.template.name || ""))])
                      ]),
                      _vm._v(" "),
                      !_vm.empty(_vm.errors[e.id])
                        ? _c("span", [
                            _vm._v("  "),
                            _c("i", { staticClass: "fa fa-exclamation-circle" })
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-end align-items-center"
                      },
                      [
                        _vm._m(1, true),
                        _vm._v(" "),
                        _c("label", { staticClass: "switch small mr-2" }, [
                          _c("input", {
                            attrs: { type: "checkbox" },
                            domProps: { checked: e.active == 1 },
                            on: {
                              change: function() {
                                return _vm.toggleBlock(e.id)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "slider round" })
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.confirm(
                                  "Ben je zeker dat je dit blok wil verwijderen?",
                                  function() {
                                    return _vm.deleteBlock(e)
                                  }
                                )
                              }
                            }
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "/assets/images/icons/actions/delete.svg",
                                draggable: "false",
                                "uk-svg": ""
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-accordion-content" },
                    [
                      _c(e.component, {
                        tag: "form",
                        staticClass:
                          "form-custom uk-grid-small uk-child-width-1-1",
                        attrs: {
                          "uk-grid": "",
                          "uk-grid": "",
                          "init-errors": _vm.errors[e.id] || {},
                          "init-block": e,
                          "init-input": _vm.input[e.id],
                          routes: _vm.routes,
                          locales: _vm.locales,
                          "field-prefix": "blocks." + e.id
                        },
                        model: {
                          value: _vm.input[e.id],
                          callback: function($$v) {
                            _vm.$set(_vm.input, e.id, $$v)
                          },
                          expression: "input[e.id]"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-sm-row flex-column align-items-sm-center mt-5"
        },
        [
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-primary mr-sm-3 mb-sm-0 mb-3",
              attrs: { disabled: _vm.isLoading },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.savePageContent($event)
                }
              }
            },
            [
              _vm.isLoading
                ? _c("div", { attrs: { "uk-spinner": "" } })
                : _vm._e(),
              _vm._v("\n            Opslaan\n        ")
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-secondary mr-sm-3 mb-sm-0 mb-3"
            },
            [_vm._v("Annuleren")]
          ),
          _vm._v(" "),
          !_vm.empty(_vm.errors)
            ? _c(
                "h5",
                {
                  staticClass:
                    "my-0 uk-text-danger uk-text-center uk-text-left@s"
                },
                [_vm._v("Gelieve de velden correct in te vullen a.u.b.")]
              )
            : _vm._e()
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "handler mr-3" }, [
      _c("img", {
        attrs: {
          src: "/assets/images/icons/actions/move.svg",
          draggable: "false",
          "uk-svg": ""
        }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicator mr-3" }, [
      _c("i", { staticClass: "fas fa-chevron-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }