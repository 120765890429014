<template>
    <div>
        <input type="hidden" :name="name" v-model="content">

        <ckeditor
            :type="type"
            :editorUrl="editorUrl"
            :config="config"
            :tag-name="tagName"
            :read-only="readOnly"
            v-model="content"
        ></ckeditor>
    </div>
</template>

<script>
    export default {
        name: "CkeditorWrapper",
        data() {
            return {
                content: this.value || ''
            }
        },
        props: {
            type: {
                type: String,
                required: false
            },
            editorUrl: {
                type: String,
                required: false,
            },
            config: {
                type: Object,
                required: false,
                default: () => {return {}; }
            },
            tagName: {
                type: String,
                required: false
            },
            value: {
                type: String,
                required: false
            },
            readOnly: {
                type: Boolean,
                default: false,
                required: false
            },
            name: {
                type: String,
                required: true
            },
            uploadRoute: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>