<template>
    <component :is="tag || 'a'" @click.prevent="confirm(body, clickHandler)">
        <slot></slot>
    </component>
</template>

<script>
    import { confirm } from "../functions";

    export default {
        name: "ConfirmButton",
        methods: {
            confirm,
            clickHandler() {
                this.callback(window);
            }
        },
        props: {
            callback: {
                type: Function,
                required: true
            },
            body: {
                type: String,
                required: false,
                default: ''
            },
            tag: {
                type: String,
                default: 'a',
                required: false
            }
        }
    }
</script>

<style scoped>

</style>