<template>
    <tbody ref="sortable">
        <slot></slot>
    </tbody>
</template>

<script>
    export default {
        name: "Sortable",
        mounted() {
            this.sortable = UIkit.sortable(this.$refs.sortable, {
                handle: '.handle'
            });

            UIkit.util.on(this.$refs.sortable, 'moved', e => this.handleSort(e));
        },
        methods: {
            handleSort(e) {
                const data = _.map(e.currentTarget.children, element => {
                    return parseInt(element.dataset.key);
                });

                axios.post(this.route, {positions: data});
            }
        },
        props: {
            route: {
                type: String,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>