var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "uk-margin-medium-top" }, [
    _c("table", { staticClass: "uk-width-expand" }, [
      _c("thead", [
        _c("tr", { staticClass: "uk-text-left" }, [
          _c("th", { staticClass: "uk-padding-small-bottom" }, [
            _vm._v(_vm._s(_vm.translations.price || ""))
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "uk-padding-small-bottom" }, [
            _vm._v(_vm._s(_vm.translations.size || ""))
          ]),
          _vm._v(" "),
          _c("th", { staticClass: "uk-padding-small-bottom" }, [
            _vm._v(_vm._s(_vm.translations.quantity || ""))
          ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.products, function(product, index) {
          return _c("tr", [
            _c("td", [
              _vm._v(
                _vm._s(product.price_formatted) + " / " + _vm._s(product.unit)
              )
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(product.size || 0))]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.number",
                    value: _vm.list[index].quantity,
                    expression: "list[index].quantity",
                    modifiers: { number: true }
                  }
                ],
                staticClass:
                  "uk-input product-card__input--number uk-margin-small-right",
                attrs: { type: "number", min: "1", id: "quantity" },
                domProps: { value: _vm.list[index].quantity },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.list[index],
                      "quantity",
                      _vm._n($event.target.value)
                    )
                  },
                  blur: function($event) {
                    return _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              product.stock > 0
                ? _c("div", { staticClass: "uk-inline-block uk-text-middle" }, [
                    _vm._m(0, true)
                  ])
                : product.stock < 1 && product.restock_date != null
                ? _c(
                    "div",
                    { staticClass: "uk-inline-block uk-text-middle " },
                    [
                      _vm._m(1, true),
                      _vm._v(" "),
                      _c("span", { staticClass: "uk-margin-small-left" }, [
                        _vm._v(
                          "(" + _vm._s(product.restock_date_formatted) + ")"
                        )
                      ])
                    ]
                  )
                : _c("div", { staticClass: "uk-inline-block" }, [
                    _vm._m(2, true)
                  ])
            ])
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass:
          "uk-button uk-button uk-button-secondary uk-margin-medium-top uk-margin-small-bottom",
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.addToCart($event)
          }
        }
      },
      [
        _vm._v(
          "\n        " + _vm._s(_vm.translations.addToCart || "") + "\n    "
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "uk-text-large" }, [
      _c("i", { staticClass: "uk-text-success fa fa-check-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "uk-text-blue uk-text-large fa fa-circle" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "uk-text-large" }, [
      _c("i", { staticClass: "uk-text-danger fa fa-times-circle" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }