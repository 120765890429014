var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "uk-form-controls" }, [
    _vm.label
      ? _c("div", { staticClass: "uk-width-1-1" }, [
          _c(
            "label",
            {
              staticClass: "mr-sm-4 mb-sm-0 mb-3 form-custom__label",
              attrs: { for: _vm.id || _vm._uid + "-" + _vm.name }
            },
            [_vm._v(_vm._s(_vm.label))]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "textarea",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.val,
            expression: "val"
          }
        ],
        staticClass: "uk-textarea uk-resize-vertical",
        class: { "uk-form-danger": _vm.errors.length > 0 },
        attrs: {
          id: _vm.id || _vm._uid + "-" + _vm.name,
          name: _vm.name,
          required: _vm.required,
          placeholder: _vm.placeholder,
          rows: _vm.rows
        },
        domProps: { value: _vm.val },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.val = $event.target.value
            },
            function($event) {
              return _vm.$emit("input", _vm.val)
            }
          ]
        }
      },
      [_vm._v(_vm._s(_vm.value || ""))]
    ),
    _vm._v(" "),
    _vm.errors.length > 0
      ? _c("p", { staticClass: "text--small uk-form-danger mt-2" }, [
          _c("strong", [_vm._v(_vm._s(_vm.errors[0]))])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }