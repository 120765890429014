var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.cart.items.length > 0
    ? _c("div", [
        _c("div", { staticClass: "cart__footer" }, [
          _c("div", { staticClass: "cart__footer__delivery" }, [
            _c(
              "div",
              {
                staticClass:
                  "uk-width-1-1@s uk-width-3-5@m cart__footer__delivery--title"
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "mb-2 uk-display-block uk-text-bold",
                    attrs: { for: "dropshipping" }
                  },
                  [_vm._v(_vm._s(_vm.translations.dropshipping))]
                ),
                _vm._v(" "),
                _c("toggle-input", {
                  attrs: { name: "dropshipping" },
                  on: { input: _vm.updateDropshipping },
                  model: {
                    value: _vm.dropshipping,
                    callback: function($$v) {
                      _vm.dropshipping = $$v
                    },
                    expression: "dropshipping"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _vm.cart.dropshipping
              ? _c(
                  "form",
                  {
                    staticClass: "form-custom uk-form",
                    attrs: { "uk-grid": "" }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "uk-width-1-1@s uk-width-3-5@m mt-3" },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "mb-2 uk-display-block uk-text-bold",
                            attrs: { for: "dropshipping" }
                          },
                          [_vm._v(_vm._s(_vm.translations.country))]
                        ),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.dropshipping_country_id,
                                expression: "dropshipping_country_id",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "uk-select",
                            attrs: {
                              name: "dropshipping_country_id",
                              id: "dropshipping_country_id"
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return _vm._n(val)
                                    })
                                  _vm.dropshipping_country_id = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.updateDropshipping
                              ]
                            }
                          },
                          [
                            _c(
                              "option",
                              { attrs: { disabled: "", value: "" } },
                              [_vm._v(_vm._s(_vm.translations.pickachoice))]
                            ),
                            _vm._v(" "),
                            _vm._l(_vm.countries, function(shortcode, id) {
                              return _c("option", { domProps: { value: id } }, [
                                _vm._v(
                                  _vm._s(_vm.translations.countries[shortcode])
                                )
                              ])
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cart__footer__totals" }, [
            _c("div", [
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.subtotal) + " "),
                _c("span", { staticClass: "text--small" })
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h3", { staticClass: "my-0" }, [
                _vm._v("€ " + _vm._s(_vm.cart.subtotal_formatted))
              ])
            ]),
            _vm._v(" "),
            _vm.cart.discount > 0
              ? _c("div", [
                  _c("p", { staticClass: "my-0" }, [
                    _vm._v(_vm._s(_vm.translations.discount) + " "),
                    _c("span", { staticClass: "text--small" })
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.cart.discount > 0
              ? _c("div", [
                  _c("h3", { staticClass: "my-0" }, [
                    _vm._v("- € " + _vm._s(_vm.cart.discount_formatted))
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.vat))
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h3", { staticClass: "my-0" }, [
                _vm._v("€ " + _vm._s(_vm.cart.vat_formatted))
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("p", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.shipping_costs))
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _vm.cart.shipping_type === "after"
                ? _c("h3", { staticClass: "my-0 uk-text-small" }, [
                    _vm._v(_vm._s(_vm.translations.payment_after))
                  ])
                : _vm.cart.shipping_cost === 0
                ? _c("h3", { staticClass: "my-0" }, [_vm._v("€ 0,-")])
                : _c("h3", { staticClass: "my-0" }, [
                    _vm._v("€ " + _vm._s(_vm.cart.shipping_cost_formatted))
                  ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h5", { staticClass: "my-0" }, [
                _vm._v(_vm._s(_vm.translations.total))
              ])
            ]),
            _vm._v(" "),
            _c("div", [
              _c("h2", { staticClass: "my-0 uk-text-primary" }, [
                _vm._v("€ " + _vm._s(_vm.cart.total_formatted))
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-between align-items-center mt-5"
          },
          [
            _c("a", { staticClass: "uk-link", attrs: { href: "/" } }, [
              _c("i", { staticClass: "fas fa-arrow-left mr-3" }),
              _vm._v(" " + _vm._s(_vm.translations.continue_shopping))
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "uk-button uk-button-primary",
                attrs: { href: _vm.routes.finish }
              },
              [_vm._v(_vm._s(_vm.translations.finish))]
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }