var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "uk-width-1-1" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-sm-row flex-column justify-content-between align-items-sm-center mb-sm-4 mb-4"
        },
        [
          _c("h3", { staticClass: "my-sm-0 mt-0 mb-3" }, [
            _vm._v(_vm._s(_vm.label))
          ]),
          _vm._v(" "),
          _vm.types.length > 1
            ? _c(
                "button",
                {
                  staticClass: "uk-button uk-button-small uk-button-primary",
                  attrs: {
                    disabled: _vm.max !== null && _vm.fields.length >= _vm.max,
                    type: "button"
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-plus mr-2" }),
                  _vm._v(" Toevoegen")
                ]
              )
            : _c(
                "button",
                {
                  staticClass: "uk-button uk-button-primary uk-button-small",
                  attrs: {
                    disabled: _vm.max !== null && _vm.fields.length >= _vm.max
                  },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return (function() {
                        return _vm.addBlock(_vm.types[0])
                      })($event)
                    }
                  }
                },
                [
                  _c("i", { staticClass: "fas fa-plus mr-2" }),
                  _vm._v(" Toevoegen")
                ]
              ),
          _vm._v(" "),
          _vm.types.length > 1
            ? _c(
                "div",
                {
                  staticClass: "custom-dropdown",
                  attrs: {
                    "uk-dropdown":
                      "mode: click; pos: bottom-right; animation: uk-animation-slide-top-small;"
                  }
                },
                [
                  _c(
                    "ul",
                    { staticClass: "uk-nav uk-dropdown-nav" },
                    _vm._l(_vm.types, function(type) {
                      return _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function() {
                                return _vm.addBlock(type)
                              }
                            }
                          },
                          [_vm._v(_vm._s(type))]
                        )
                      ])
                    }),
                    0
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("hr")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { ref: "sortable", staticClass: "uk-width-1-1 uk-padding-small" },
      _vm._l(_vm.fields, function(field, index) {
        return _c(
          "ul",
          {
            staticClass: "page-accordion",
            attrs: { "data-index": index, "uk-accordion": "" },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
              }
            }
          },
          [
            _c("li", [
              _c(
                "a",
                { staticClass: "uk-accordion-title uk-accordion-title--small" },
                [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _vm.sort
                      ? _c("div", { staticClass: "repeater-handler mr-3" }, [
                          _c("img", {
                            attrs: {
                              src: "/assets/images/icons/actions/move.svg",
                              draggable: "false",
                              "uk-svg": ""
                            }
                          })
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", { staticClass: "block-name my-0" }, [
                      _c("strong", [_vm._v(_vm._s(field._type))])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-end align-items-center"
                    },
                    [
                      _vm._m(0, true),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              return _vm.deleteBlock(index)
                            }
                          }
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: "/assets/images/icons/actions/delete.svg",
                              draggable: "false",
                              "uk-svg": ""
                            }
                          })
                        ]
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "uk-accordion-content" },
                [
                  _vm._t(field._type, null, {
                    handleInput: function(prop, value) {
                      return _vm.updateBlock(prop, value, index)
                    },
                    input: _vm.fields[index],
                    index: index,
                    errors: _vm.errors[index]
                  })
                ],
                2
              )
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "indicator mr-3" }, [
      _c("i", { staticClass: "fas fa-chevron-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }