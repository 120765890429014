<template>
    <div>
        <div class="uk-grid-small align-items-center" uk-grid>
            <div class="uk-width-1-5">
                <p class="my-0 text--small">{{ translations.quantity || 'Aantal' }}:</p>
            </div>

            <div class="uk-width-2-5">
                <input v-model="quantity" inputmode="numeric" :max="product.available_stock" type="number" name="amount" class="custom-number-input mr-3" min="1" value="1">
            </div>
        </div>

        <add-to-cart-button
                class="uk-button uk-button-success mt-4"
                :route="route"
                :purchasable-id="product.id"
                :purchasable-type="product.purchasable_type"
                :quantity="quantity"
        >
            <img src="/assets/images/icons/cart.svg" class="mr-3" draggable="false" uk-svg>
            {{ translations.addToCart || 'In mijn winkelwagen' }}
        </add-to-cart-button>
    </div>
</template>

<script>
    import AddToCartButton from "./AddToCartButton";
    export default {
        name: "AddToCartComponent",
        components: {AddToCartButton},
        data() {
            return {
                quantity: 1
            }
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            route: {
                type: String,
                required: true
            },
            translations: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>

</style>
